import { IconName } from '@fortawesome/free-regular-svg-icons';
import { ThemePalette } from '@angular/material/core';

/**
 * Defines the options that should be passed into the confirmation dialog.
 */
export interface IConfirmationDialogOptions {
  id?: string;

  // The title displayed on the confirmation dialog.
  // DEFAULT: NONE
  title?: string;

  // The content message displayed on the confirmation dialog.
  // DEFAULT: NONE
  // REQUIRED
  content: string;

  // Allows the user to define configurable buttons on the dialog.
  // DEFAULT: If no actions are passed by the user, the dialog will default to two buttons--YES and CANCEL.
  actions?: IConfirmationDialogActionItem[];

  // Stops dialog from closing when user clicks outside of dialog - will only close if an action button or the close button are pressed
  disableClose?: boolean;

  typeToConfirm?: string;

  width?: string;
  height?: string;
}

/**
 * Defines actions that can be configured to display on the confirmation dialog.
 */
export interface IConfirmationDialogActionItem {
  // A UNIQUE user-defined string that will be an identifier for this action.
  // REQUIRED
  key: string;

  // A label for the button that you are defining.
  // REQUIRED -- Only if no icon is present.
  label?: string;

  // An icon class to display on the button.
  // REQUIRED -- Only if no label is present.
  icon?: IconName;

  // Which bootstrap classes to apply to your button.
  // OPTIONAL: But recommended. Otherwise, we can guess and apply default classes, but it may not be desirable.
  classes?: string;

  color?: ThemePalette;

  // This awaits the action if needed
  awaitAction?: boolean;

  // A function that will be called when the button is clicked.
  // OPTIONAL: But recommended, otherwise it will just close the dialog.
  action?: () => void | Promise<void> | Promise<string>;
}

/**
 * An easy to implement default enum of what the default dialog result will return to the user.
 */
export enum DEFAULT_DIALOG_RESULT {
  YES = 'ACTION_KEY_YES',
  CANCEL = 'ACTION_KEY_CANCEL',
  FAILED_CONFIRMATION = 'FAILED_CONFIRMATION',
  CHECK_CONFIRMATION = 'CHECK_CONFIRMATION'
}
