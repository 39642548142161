<div class="container">
  <app-schoolwide-report-filters
  [loadingItems]="isLoading"
  [filters]="filters"
  (filtersUpdated)="onFiltersUpdated($event)"
  (applyFilters)="applyFilters()">
  </app-schoolwide-report-filters>
  <app-schoolwide-report-actions
    (filtersUpdated)="onInstantFiltersUpdated($event)"
    [filters]="filters">
  </app-schoolwide-report-actions>
  <app-schoolwide-report-table
    *ngIf="!!dataArr.length && !isLoading"
    [showPagination]="showPagination"
    [tableFilters]="tableFilters"
    [dataArr]="dataArr">
  </app-schoolwide-report-table>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>
</div>
