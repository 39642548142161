import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseTableComponent } from '../../../shared/tables/base-table/base-table';
import {
  AccruedReportFiltersDto,
  AccruedReportRowResponseDto
} from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../shared/tables/table-filters/table-filters.interface';
import { ActivatedRoute } from '@angular/router';
import { AppReportsService } from '../reports.service';
import { TableFilterOptions } from '../../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../../shared/tables/table-filters/table-filters-page-key.enum';
import { Subject, takeUntil } from 'rxjs';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { AppConfirmationDialogService } from '../../../shared/confirmation-dialog/confirmation-dialog.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { DEFAULT_DIALOG_RESULT } from '../../../shared/confirmation-dialog/confirmation-dialog-options.interface';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';

@Component({
  selector: 'app-accrued-report',
  templateUrl: './accrued-report.template.html',
  styleUrls: ['./accrued-report.scss']
})
export class AppAccruedReportComponent
  extends BaseTableComponent<AccruedReportRowResponseDto>
  implements OnInit, OnDestroy
{
  public showPagination: boolean;
  public filters: AccruedReportFiltersDto;
  public totalCount: number = 0;
  public studentsCount: number = 0;
  private destroy$ = new Subject<void>();

  public constructor(
    public route: ActivatedRoute,
    public reportService: AppReportsService,
    public pageHeaderService: AppPageHeaderService,
    public confirmationDialog: AppConfirmationDialogService,
    public toastService: AppToastManagerService
  ) {
    super();
  }

  public ngOnInit() {
    this.loadData();

    this.pageHeaderService.buttonAction$
      .pipe(takeUntil(this.destroy$))
      .subscribe(async (action: string) => {
        if (action === HeaderButtonAction.EXPORT) {
          const ref = this.confirmationDialog.openDialog({
            title: `Accrued Records Report Export`,
            content:
              'Are you sure you want to export the accrued records report?'
          });
          ref.afterClosed().subscribe(async (result: string) => {
            if (result === DEFAULT_DIALOG_RESULT.YES) {
              await this.reportService.getAccruedReport(this.filters, true);
              this.toastService.success(
                'Export started, we will email you with a link to the file'
              );
            }
          });
        }
      });
  }

  public ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  public loadData(): void {
    const schoolId = this.StorageManager.getLoggedInUser().currentSchoolId;
    this.isLoading = true;
    const data = this.route.snapshot.data.data;
    this.dataArr = data.results[0].results;
    this.totalCount = data.results[0].totalCount;
    this.studentsCount = data.results[0].studentsCount;
    this.tableFilters = TableFilterOptions.getPageDefault(
      TableFiltersPageKeyEnum.BEHAVIOR_CODE_ACCRUED_REPORT
    );
    this.filters = new AccruedReportFiltersDto({
      ...this.tableFilters,
      schoolId,
      active: true
    });
    this.tableFilters.count = data.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }

  public onFiltersUpdated(filters: AccruedReportFiltersDto) {
    this.filters = filters;
  }

  public onInstantFiltersUpdated(filters: AccruedReportFiltersDto) {
    this.onFiltersUpdated(filters);
    this.applyFilters();
  }

  public async updateTableFilters(
    newTableFilters: ITableFilters
  ): Promise<void> {
    this.filters = new AccruedReportFiltersDto({
      ...this.filters,
      ...newTableFilters,
      active: false
    });

    await this.applyFilters();
  }

  public async applyFilters(): Promise<void> {
    this.isLoading = true;
    const response = await this.reportService.getAccruedReport(this.filters);
    const data = response.results[0];
    this.dataArr = data.results;
    this.totalCount = data.totalCount;
    this.studentsCount = data.studentsCount;
    this.tableFilters.count = response.options.totalItems;
    this.showPagination = this.dataArr?.length < this.tableFilters?.count;
    this.isLoading = false;
  }
}
