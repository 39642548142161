<div class="behavior-code-entry-confirmations">
  <div class="content">
    <app-behavior-code-entry-confirm-reaction-table
      [dataArr]="reactionEntries"
      [reloadTable]="reloadTable.bind(this)"
      [tableFilters]="tableFilters"
      (tableFiltersUpdated)="updateTableFilters($event)"
      (editReaction)="
        openEditReactionModal($event)
      "></app-behavior-code-entry-confirm-reaction-table>
  </div>
</div>
