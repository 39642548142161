"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __metadata = (this && this.__metadata) || function (k, v) {
    if (typeof Reflect === "object" && typeof Reflect.metadata === "function") return Reflect.metadata(k, v);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SftpSettingsBasicResponseDto = void 0;
const mappable_base_1 = require("../../mappable.base");
const class_transformer_1 = require("class-transformer");
const class_validator_1 = require("class-validator");
const nested_dto_config_1 = require("../../nested-dto-config");
const enums_1 = require("../../enums");
const boolean_transform_1 = require("../../constants/boolean.transform");
class SftpSettingsBasicResponseDto extends mappable_base_1.MappableBase {
    getFields() {
        return [
            'id',
            'accountId',
            'schoolGroupId',
            'schoolId',
            'isSchoolGroupLevel',
            'importSubject',
            'automaticImportEnabled'
        ];
    }
    mapFields(source) {
        super.mapFields(source);
        return this;
    }
    getNested() {
        return [
            nested_dto_config_1.NestedDtoConfig.relationConfig('schoolGroup', 'schoolGroup'),
            nested_dto_config_1.NestedDtoConfig.relationConfig('school', 'school')
        ];
    }
    id;
    schoolId;
    schoolGroupId;
    isSchoolGroupLevel;
    school;
    schoolGroup;
    importSubject;
    automaticImportEnabled;
}
exports.SftpSettingsBasicResponseDto = SftpSettingsBasicResponseDto;
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SftpSettingsBasicResponseDto.prototype, "id", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SftpSettingsBasicResponseDto.prototype, "schoolId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsPositive)(),
    __metadata("design:type", Number)
], SftpSettingsBasicResponseDto.prototype, "schoolGroupId", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SftpSettingsBasicResponseDto.prototype, "isSchoolGroupLevel", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], SftpSettingsBasicResponseDto.prototype, "school", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", Object)
], SftpSettingsBasicResponseDto.prototype, "schoolGroup", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    __metadata("design:type", String)
], SftpSettingsBasicResponseDto.prototype, "importSubject", void 0);
__decorate([
    (0, class_transformer_1.Expose)(),
    (0, class_validator_1.IsBoolean)(),
    (0, class_transformer_1.Transform)(boolean_transform_1.BooleanStringTransform),
    __metadata("design:type", Boolean)
], SftpSettingsBasicResponseDto.prototype, "automaticImportEnabled", void 0);
