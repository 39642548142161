import { ActivatedRoute, Router } from '@angular/router';
import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { AppSaveBarService } from '../../../shared/save-bar/save-bar.service';
import { AppSchoolsServices } from '../schools.service';
import { AppToastManagerService } from 'src/app/shared/services/toast-manager.service';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import { FormBuilder, FormGroup } from '@angular/forms';
import { IFormError } from '../../../shared/validation/form-error.interface';
import { plainToInstance } from 'class-transformer';
import { RESPONSIVE_GRID_PRESETS } from '../../../shared/responsive-grid-columns';
import { Subscription } from 'rxjs';
import {
  dtoToFormGroup,
  formCanSave,
  formsTouched,
  mapApiErrorResponse,
  validateAndGetValue
} from '../../../shared/validation/validation.util';
import {
  IDisplayData,
  LoginResponseDto,
  PrivilegeEnum,
  SchoolCreateDto,
  SchoolDetailResponseDto,
  SchoolResponseDto,
  SchoolSettings,
  SchoolUpdateDto,
  StateEnum,
  TimezoneDict
} from '@whetstoneeducation/hero-common';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { MatDialog } from '@angular/material/dialog';
import { AppSubscriptionCreateEditComponent } from './subscription-create-edit/subscription-create-edit.component';
import { AppBackButtonModalComponent } from '../../../shared/back-button-modal/back-button-modal.component';
import { AppDeactivateRecordsModalComponent } from './deactivate-records-modal/deactivate-records-modal.component';

@Component({
  styleUrls: ['./school-create-edit.scss'],
  selector: 'app-school-create-edit',
  templateUrl: './school-create-edit.template.html'
})
export class AppSchoolCreateEditComponent
  extends BaseComponent
  implements AfterViewInit, OnDestroy
{
  private readonly CREATE_OPTIONS = {
    enableImplicitConversion: true,
    exposeUnsetFields: true
  };
  private readonly UPDATE_OPTIONS = {
    enableImplicitConversion: true,
    exposeUnsetFields: true
  };
  public countryOptions: IDisplayData[] = [
    { value: 'US', display: 'United States' }
  ];
  public errors: IFormError[] = [];
  public school: SchoolCreateDto | SchoolUpdateDto;
  public schoolForm: FormGroup;
  public availableSchoolGroups: IDisplayData[] = [];
  public pageHeaderSubscription: Subscription;
  public stateOptions: IDisplayData[] = Object.values(StateEnum).map(
    (value) => ({
      value,
      display: value
    })
  );
  public valueChangesSubscriptions: Subscription[] = [];
  public isCreating: boolean;
  public schoolTerms: any[] = [];
  public isLoading: boolean = false;
  public timezoneOptions: { value: string; label: string }[] = [];

  constructor(
    private formBuilder: FormBuilder,
    private toastService: AppToastManagerService,
    public route: ActivatedRoute,
    public router: Router,
    public saveBar: AppSaveBarService,
    public schoolsServices: AppSchoolsServices,
    public pageHeaderService: AppPageHeaderService,
    public dialog: MatDialog
  ) {
    super({ route, router });
    this.setupHeaderButtonSubscriptions();
  }

  async ngAfterViewInit(): Promise<void> {
    this.isLoading = true;
    this.timezoneOptions = [{value: null, label: ''}, ...Object.values(TimezoneDict)];
    await this.loadData(this.route.snapshot.data.data);
    this.schoolForm = dtoToFormGroup(this.school, this.formBuilder, {
      exclude: this.isUpdateSchool() ? ['schoolGroupId'] : []
    });

    this.valueChangesSubscriptions.push(
      this.schoolForm.valueChanges.subscribe(async () => {
        if (this.school instanceof SchoolUpdateDto) {
          this.school = await validateAndGetValue<SchoolUpdateDto>(
            this.schoolForm,
            SchoolUpdateDto
          );
          this.schoolTerms = await this.schoolsServices.getSchoolTerms(
            this.school.id
          );
        } else {
          this.school = await validateAndGetValue<SchoolCreateDto>(
            this.schoolForm,
            SchoolCreateDto
          );
        }
      })
    );
    this.isLoading = false;
  }

  ngOnDestroy() {
    this.saveBar.hide();
    this.pageHeaderSubscription.unsubscribe();
    this.valueChangesSubscriptions.forEach((subscription) =>
      subscription.unsubscribe()
    );
  }

  public get responsiveGridPresets() {
    return RESPONSIVE_GRID_PRESETS.TWO_COLUMN;
  }

  public setupHeaderButtonSubscriptions() {
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (
            action === HeaderButtonAction.SAVE &&
            formsTouched([this.schoolForm], this.toastService)
          ) {
            if (formCanSave(this.schoolForm, this.toastService, false)) {
              const school = await this.saveSchool(this.school);
              if (school && school.id) {
                const user = this.StorageManager.getLoggedInUser();
                const schoolGroup = user.schoolGroups.find(
                  (group) => group.id === school.schoolGroupId
                );
                if (schoolGroup) {
                  const schoolIndex = schoolGroup.schools.findIndex(
                    (school) => school.id === school.id
                  );
                  if (schoolIndex > -1) {
                    schoolGroup.schools[schoolIndex] = {
                      id: school.id,
                      name: school.name
                    };
                  } else {
                    schoolGroup.schools.push({
                      id: school.id,
                      name: school.name
                    });
                  }
                }
                this.StorageManager.saveUserInfo(user);
                await this.router.navigate(['schools']);
              }
            }
          }

          if (
            action === HeaderButtonAction.EDIT_SUBSCRIPTION &&
            this.isCreating === false
          ) {
            this.dialog.open(AppSubscriptionCreateEditComponent, {
              data: {
                schoolId: this.route.snapshot.data.data.id,
                onClose: async () => {
                  this.dialog.closeAll();
                }
              },
              width: '500px',
              height: 'auto',
              autoFocus: false
            });
          }

          if (action === HeaderButtonAction.BACK) {
            if (formsTouched([this.schoolForm])) {
              // show dialog asking you sure, things will not be saved
              // AppBackButtonModalComponent
              this.dialog.open(AppBackButtonModalComponent, {
                data: {
                  navigateTo: '/schools',
                  onClose: async () => {
                    this.dialog.closeAll();
                  }
                },
                width: '500px',
                height: 'auto',
                autoFocus: false
              });
            } else {
              // just go back to school list page
              await this.router.navigate(['/schools']);
            }
          }

          if (action === HeaderButtonAction.DEACTIVATE_RECORDS) {
            this.dialog.open(AppDeactivateRecordsModalComponent, {
              width: '500px',
              autoFocus: true,
              data: {
                schoolId: this.route.snapshot.data.data.id
              }
            });
          }
        }
      );
  }

  public isUpdateSchool(): boolean {
    return this.school instanceof SchoolUpdateDto;
  }

  public async loadData(data: SchoolResponseDto) {
    this.availableSchoolGroups =
      this.StorageManager.getLoggedInUser().schoolGroups.map((group) => {
        return { value: group.id, display: group.name };
      });
    const school = data;
    try {
      if (!school.id) {
        this.school = plainToInstance(
          SchoolCreateDto,
          school,
          this.CREATE_OPTIONS
        );
        this.isCreating = true;
        this.school.settings = new SchoolSettings({});
      } else {
        this.schoolTerms = await this.schoolsServices.getSchoolTerms(school.id);
        this.school = plainToInstance(
          SchoolUpdateDto,
          school,
          this.UPDATE_OPTIONS
        );
        this.isCreating = false;
      }
    } catch (error) {
      this.toastService.error('An error occurred while to loading the school!');
    }
  }

  public async saveSchool(
    school: SchoolCreateDto | SchoolUpdateDto
  ): Promise<SchoolDetailResponseDto> {
    try {
      this.isLoading = true;
      let schoolResult: SchoolDetailResponseDto;
      if (school instanceof SchoolCreateDto) {
        schoolResult = await this.schoolsServices.createSchool(school);
        this.school = plainToInstance(
          SchoolUpdateDto,
          schoolResult,
          this.UPDATE_OPTIONS
        );
        this.toastService.success('School created successfully!');
        this.isCreating = false;
      } else {
        schoolResult = await this.schoolsServices.updateSchool(school);
        this.school = plainToInstance(
          SchoolUpdateDto,
          schoolResult,
          this.UPDATE_OPTIONS
        );
        this.toastService.success('School saved successfully!');
      }
      return schoolResult;
    } catch (errorResponse) {
      this.errors = mapApiErrorResponse(errorResponse);
      this.toastService.error(
        'An error occurred when attempting to save your school!'
      );
    }
    this.isLoading = false;
  }

  protected readonly PrivilegeEnum = PrivilegeEnum;
}
