import { AfterViewInit, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';
import { SchoolwideReportResponseDto } from '@whetstoneeducation/hero-common';

export enum SchoolwideReportTableColumns {
  STUDENT_ID = 'Student ID',
  STUDENT_NAME = 'Student Name',
  GRADE_LEVEL = 'Grade Level',
  AVAILABLE_POINTS = 'Available Points',
  TOTAL_POINTS = 'Total Points',
}

@Component({
  selector: 'app-schoolwide-report-table',
  templateUrl: './schoolwide-report-table.template.html',
  styleUrls: ['./schoolwide-report-table.scss']
})
export class AppSchoolwideReportTable implements AfterViewInit {
  @ViewChild('tooltipHeader') tooltipHeader!: TemplateRef<any>;
  @ViewChild('pointsColumn') pointsColumn!: TemplateRef<any>;
  @Input() showPagination: boolean;
  @Input() dataArr: SchoolwideReportResponseDto[];
  @Input() tableFilters: ITableFilters;

  public templates: { [key: string]: TemplateRef<any> } = {};

  public columns: typeof SchoolwideReportTableColumns = SchoolwideReportTableColumns;

  public constructor() {}

  ngAfterViewInit(): void {
    if (this.tooltipHeader) {
      this.templates['tooltipHeader'] = this.tooltipHeader;
    }

    if (this.pointsColumn) {
      this.templates['pointsColumn'] = this.pointsColumn;
    }
  }

  public getColumnTitle(column: string): any {
    switch (column) {
      case SchoolwideReportTableColumns.AVAILABLE_POINTS:
        return {
          context: {
            title: 'Available Points',
            message: 'Total points including negatives',
          },
          template: this.templates.tooltipHeader,
        }
      case SchoolwideReportTableColumns.TOTAL_POINTS:
        return {
          context: {
            title: 'Total Points',
            message: 'Total points with only positives',
          },
          template: this.templates.tooltipHeader,
        }
      default:
        return column;
    }
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case SchoolwideReportTableColumns.STUDENT_ID:
        return row.studentId || 'N/A';
      case SchoolwideReportTableColumns.STUDENT_NAME:
        return row.studentName;
      case SchoolwideReportTableColumns.GRADE_LEVEL:
        return row.grade;
      case SchoolwideReportTableColumns.AVAILABLE_POINTS:
        return {
          context: {
            value: row.availablePoints || 0,
          },
          template: this.templates.pointsColumn,
        };
      case SchoolwideReportTableColumns.TOTAL_POINTS:
        return {
          context: {
            value: row.totalPoints || 0,
          },
          template: this.templates.pointsColumn,
        };
      default:
        return null;
    }
  }
}
