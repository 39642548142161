import { Component, OnInit } from '@angular/core';
import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  StudentProfileResponseDto,
  UserTypeEnum
} from '@whetstoneeducation/hero-common';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { AppPageHeaderService } from '../../../shared/page-header/page-header.service';
import { HeaderButtonAction } from '../../../shared/page-header/header-button';
import { AppBehaviorCodeEntryCreateModalComponent } from '../../behavior-code-entry/behavior-code-entry-create-modal/behavior-code-entry-create-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { faBellRing } from '@fortawesome/pro-solid-svg-icons';
import { StudentInfoPrintService } from '../students-info-printer/student-info-print.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { PrinterService } from '../../../shared/printer/printer.service';

@Component({
  selector: 'app-student-profile',
  templateUrl: './student-profile.template.html',
  styleUrls: ['./student-profile.scss']
})
export class AppStudentProfile extends BaseComponent implements OnInit {
  public pageHeaderSubscription: Subscription;

  public profile: StudentProfileResponseDto;
  public isStudent: boolean = false;
  public noStudent: boolean = false;
  public points = 0;

  public bellIcon = faBellRing;

  constructor(
    public route: ActivatedRoute,
    public router: Router,
    private dialog: MatDialog,
    private pageHeaderService: AppPageHeaderService,
    private toastService: AppToastManagerService,
    private studentPrintInfoService: StudentInfoPrintService,
    private printerService: PrinterService
  ) {
    super();
  }

  ngOnInit(): void {
    this.profile = this.route.snapshot.data.data;
    if (!this.profile) {
      this.noStudent = true;
    } else {
      this.points = this.profile.profileData.reduce(
        (acc, data) => acc + +data.points,
        0
      );
    }
    this.isStudent = this.StorageManager.isUserType([
      UserTypeEnum.STUDENT,
      UserTypeEnum.GUARDIAN
    ]);
  }

  public async showTrackModal() {
    this.dialog.open(AppBehaviorCodeEntryCreateModalComponent, {
      width: '600px',
      panelClass: 'default-dialog',
      data: {
        studentIds: [this.profile.studentDetail.id]
      }
    });
  }

  private printStudentInfo(): void {
    try {
      this.studentPrintInfoService.setData(this.profile.studentDetail).print();
    } catch (error) {
      this.toastService.error('Error printing reaction, printer not found');
    }
  }

  ngAfterViewInit(): void {
    this.pageHeaderSubscription =
      this.pageHeaderService.buttonAction$.subscribe(
        async (action: HeaderButtonAction) => {
          if (action === HeaderButtonAction.TRACK_STUDENTS) {
            await this.showTrackModal();
          }
          if (action === HeaderButtonAction.UPDATE) {
            await this.router.navigate([
              'students',
              this.profile.studentDetail.id
            ]);
          }
          if (action === HeaderButtonAction.PRINT_STUDENT_INFO) {
            if (this.printerService.usbAvailable) {
                this.printStudentInfo();
            } else {
              this.printerService.initializeDevice();
            }
          }
        }
      );
  }

  public showError(): boolean {
    return this.isStudent && this.noStudent;
  }
}
