import { Injectable } from '@angular/core';
import { BaseService } from '../../shared/base-classes/base.service';
import { AppClientDataService } from '../../shared/services/client-data-service/app-client-data.service';
import {
  ImportSubject,
  PaginationResultsDto,
  SftpAccountCreateDto,
  SftpAccountCredentialsDto,
  SftpAccountExistingDto,
  SftpAccountExistsResponseDto,
  SftpAccountResponseDto,
  SftpImportResponseDto,
  SftpImportStatsDto,
  SftpSettingsCreateDto,
  SftpSettingsResponseDto,
  SftpSettingsUpdateDto,
  LastImportsResponseDto
} from '@whetstoneeducation/hero-common';
import { ISftpAccountListFilters } from './sftp-account-list/sftp-account-list-models/sftp-account-list-filters.interface';
import { ISftpAccountListResolverData } from './sftp-account-list/sftp-account-list-models/sftp-account-list-resolver-data.interface';
import { TableFilterOptions } from '../../shared/tables/table-filters/table-filters';
import { TableFiltersPageKeyEnum } from '../../shared/tables/table-filters/table-filters-page-key.enum';
import { logger } from '../../shared/logger';
import { AppToastManagerService } from '../../shared/services/toast-manager.service';
import { ISftpSettingsListResolverData } from './sftp-settings-list/sftp-settings-list-models/sftp-settings-list-resolver-data.interface';
import { ISftpSettingsListFilters } from './sftp-settings-list/sftp-settings-list-models/sftp-settings-list-filters.interface';
import { ISftpImportListFilters } from './sftp-import-list/sftp-import-list-models/sftp-import-list-filters.interface';
import { ISftpImportListResolverData } from './sftp-import-list/sftp-import-list-models/sftp-import-list-resolver-data.interface';
import { HttpClient } from '@angular/common/http';
import { EnvService } from '../../env.service';

@Injectable({ providedIn: 'root' })
export class AppSftpService extends BaseService {
  private readonly API_GATEWAY_URL: string;
  constructor(
    private readonly appClientDataService: AppClientDataService,
    private readonly toastService: AppToastManagerService,
    private readonly http: HttpClient,
    private readonly envService: EnvService
  ) {
    super();
    this.API_GATEWAY_URL = this.envService.apiGatewayUrl;
  }

  public async viewSftpAccount(
    sftpAccountId: number
  ): Promise<SftpAccountResponseDto> {
    return await this.appClientDataService.execute<SftpAccountResponseDto>(
      this.GET_ROUTES.SFTP_ACCOUNT_BY_ID,
      {
        pathParams: {
          id: sftpAccountId
        }
      },
      SftpAccountResponseDto
    );
  }

  public async downloadImportReport(sftpImportId: number): Promise<any[]> {
    return await this.appClientDataService.execute(
      this.GET_ROUTES.DOWNLOAD_IMPORT_REPORT,
      {
        pathParams: {
          id: sftpImportId
        }
      }
    );
  }

  public async downloadImportFile(sftpImportId: number): Promise<string[]> {
    return await this.appClientDataService.execute(
      this.GET_ROUTES.DOWNLOAD_IMPORT_FILE,
      {
        pathParams: {
          id: sftpImportId
        }
      }
    );
  }

  public async resetAccountPassword(
    sftpAccountId: number
  ): Promise<SftpAccountCredentialsDto> {
    return await this.appClientDataService.execute<SftpAccountCredentialsDto>(
      this.POST_ROUTES.RESET_SFTP_ACCOUNT_PASSWORD,
      {
        method: 'POST',
        pathParams: {
          id: sftpAccountId
        }
      },
      SftpAccountCredentialsDto
    );
  }

  public async getSftpAccountsListPageResolverData(): Promise<ISftpAccountListResolverData> {
    try {
      const defaultFilters: ISftpAccountListFilters = {
        tableFilters: TableFilterOptions.getPageDefault(
          TableFiltersPageKeyEnum.SFTP_ACCOUNTS_LIST
        )
      };
      const sftpAccounts = await this.getSftpAccountsList(defaultFilters);
      return {
        defaultFilters,
        sftpAccounts
      };
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading SFTP accounts');
    }
  }

  public async getCloudFile(
    sftpAccountId: number,
    fileName: string
  ): Promise<{ file: string; status: number }> {
    return this.appClientDataService.execute<{ file: string; status: number }>(
      this.GET_ROUTES.IMPORT_CLOUD_FILE,
      {
        queryParams: {
          accountId: sftpAccountId,
          fileName
        }
      }
    );
  }

  public async getImportStats(
    subject: ImportSubject
  ): Promise<SftpImportStatsDto[]> {
    return await this.appClientDataService.execute<SftpImportStatsDto[]>(
      this.GET_ROUTES.SFTP_IMPORT_STATS,
      {
        method: 'GET',
        pathParams: {
          importSubject: subject
        }
      },
      SftpImportStatsDto
    );
  }

  public async getSftpAccountsList(
    filters: ISftpAccountListFilters
  ): Promise<PaginationResultsDto<SftpAccountResponseDto>> {
    return await this.appClientDataService.execute<
      PaginationResultsDto<SftpAccountResponseDto>
    >(
      this.GET_ROUTES.SFTP_ACCOUNTS_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.groupLevel ? { groupLevel: filters.groupLevel } : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {})
        }
      },
      SftpAccountResponseDto
    );
  }

  public async getSftpSettingsListPageResolverData(): Promise<ISftpSettingsListResolverData> {
    try {
      const defaultFilters: ISftpSettingsListFilters = {
        tableFilters: TableFilterOptions.getPageDefault(
          TableFiltersPageKeyEnum.SFTP_SETTINGS_LIST
        )
      };
      const sftpSettings = await this.getSftpSettingsList(defaultFilters);
      return {
        defaultFilters,
        sftpSettings
      };
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading SFTP settings');
    }
  }

  public async getSftpSettingsList(
    filters: ISftpSettingsListFilters
  ): Promise<PaginationResultsDto<SftpSettingsResponseDto>> {
    return await this.appClientDataService.execute<
      PaginationResultsDto<SftpSettingsResponseDto>
    >(
      this.GET_ROUTES.SFTP_SETTINGS_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.groupLevel ? { groupLevel: filters.groupLevel } : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          ...(filters.automaticImportEnabled
            ? { automaticImportEnabled: filters.automaticImportEnabled }
            : {})
        }
      },
      SftpSettingsResponseDto
    );
  }

  public async getSftpImportsList(
    filters: ISftpImportListFilters
  ): Promise<PaginationResultsDto<SftpImportResponseDto>> {
    return await this.appClientDataService.execute<
      PaginationResultsDto<SftpImportResponseDto>
    >(
      this.GET_ROUTES.SFTP_IMPORTS_LIST,
      {
        queryParams: {
          ...(filters.tableFilters.itemsPerPage
            ? { itemsPerPage: filters.tableFilters.itemsPerPage }
            : {}),
          ...(filters.tableFilters.page
            ? { page: filters.tableFilters.page }
            : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.importStatus ? { status: filters.importStatus } : {}),
          ...(filters.importSubject
            ? { importSubject: filters.importSubject }
            : {})
        }
      },
      SftpImportResponseDto
    );
  }

  public async getSftpImportListPageResolverData(): Promise<ISftpImportListResolverData> {
    try {
      const defaultFilters: ISftpImportListFilters = {
        importSubject: ImportSubject.STUDENT_IMPORT,
        tableFilters: TableFilterOptions.getPageDefault(
          TableFiltersPageKeyEnum.SFTP_IMPORTS_LIST
        )
      };
      const sftpImports = await this.getSftpImportsList(defaultFilters);
      return {
        defaultFilters,
        sftpImports
      };
    } catch (error) {
      logger.error(error);
      this.toastService.error('Error loading SFTP imports');
    }
  }

  public async getMatchingSftpAccounts(filters: SftpAccountExistingDto) {
    return await this.appClientDataService.execute<SftpAccountResponseDto[]>(
      this.GET_ROUTES.SFTP_ACCOUNT_FIND_MATCHING,
      {
        queryParams: {
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          groupLevel: filters.groupLevel
        }
      },
      SftpAccountResponseDto
    );
  }

  public async getExistingSftpAccount(filters: SftpAccountExistingDto) {
    return await this.appClientDataService.execute<SftpAccountExistsResponseDto>(
      this.GET_ROUTES.SFTP_ACCOUNT_FIND_EXISTING,
      {
        queryParams: {
          ...(filters.schoolId ? { schoolId: filters.schoolId } : {}),
          ...(filters.schoolGroupId
            ? { schoolGroupId: filters.schoolGroupId }
            : {}),
          groupLevel: filters.groupLevel
        }
      },
      SftpAccountExistsResponseDto
    );
  }

  public async createSftpAccount(
    accountInformation: SftpAccountCreateDto
  ): Promise<SftpAccountCredentialsDto> {
    return await this.appClientDataService.execute<SftpAccountCredentialsDto>(
      this.POST_ROUTES.CREATE_SFTP_ACCOUNT,
      {
        method: 'POST',
        body: accountInformation
      },
      SftpAccountCredentialsDto
    );
  }

  public async createSftpSettings(
    settingsInformation: SftpSettingsCreateDto
  ): Promise<SftpSettingsResponseDto> {
    return await this.appClientDataService.execute<SftpSettingsResponseDto>(
      this.POST_ROUTES.CREATE_SFTP_SETTINGS,
      {
        method: 'POST',
        body: settingsInformation
      },
      SftpSettingsResponseDto
    );
  }

  public async updateSftpSettings(
    settingsInformation: SftpSettingsUpdateDto
  ): Promise<SftpSettingsResponseDto> {
    return await this.appClientDataService.execute<SftpSettingsResponseDto>(
      this.POST_ROUTES.UPDATE_SFTP_SETTINGS,
      {
        method: 'POST',
        body: settingsInformation,
        pathParams: {
          id: settingsInformation.id
        }
      },
      SftpSettingsResponseDto
    );
  }

  public async deleteExportSettings(id: number) {
    return
  }

  public async deleteImportSettings(
    id: number
  ){
    return await this.appClientDataService.execute(
      this.DELETE_ROUTES.DELETE_IMPORT_SETTINGS,
      {
        method: 'DELETE',
        pathParams: {
          id
        }
      },
    );
  }

  public async getSftpSettings(
    sftpSettingsId: number
  ): Promise<SftpSettingsResponseDto> {
    return await this.appClientDataService.execute<SftpSettingsResponseDto>(
      this.GET_ROUTES.SFTP_SETTINGS_BY_ID,
      {
        pathParams: {
          id: sftpSettingsId
        }
      },
      SftpSettingsResponseDto
    );
  }

  public async runImport(
    sftpSettingsId: number
  ): Promise<Boolean> {
    return await this.appClientDataService.execute<Boolean>(
      this.GET_ROUTES.RUN_IMPORT,
      {
        pathParams: {
          id: sftpSettingsId
        }
      }
    );
  }

  public async uploadFileImport(sftpSettingsId: number, file: File) {
    const formData = new FormData();
    formData.append('file', file, file.name);
    return this.http.post(
      this.API_GATEWAY_URL +
        this.POST_ROUTES.UPLOAD_FILE_IMPORT +
        `/${sftpSettingsId}`,
      formData,
      { headers: { Version: '1' }, withCredentials: true }
    );
  }

  public async getTopImports(): Promise<LastImportsResponseDto[]> {
    return await this.appClientDataService.execute<LastImportsResponseDto[]>(
      this.GET_ROUTES.SFTP_TOP_IMPORTS
    )
  }
}
