import { ICustomRoute } from '../../app-routing.module';
import { dailyActivityReportResolver } from './daily-activity-report/daily-activity-report.resolver';
import { AppAccruedReportComponent } from './accrued-report/accrued-report.component';
import { accruedReportResolver } from './accrued-report/accrued-report.resolver';
import { AppSchoolActivityReportComponent } from './school-activity-report/school-activity-report.component';
import { schoolActivityReportResolver } from './school-activity-report/school-activity-report.resolver';
import { AppSchoolwideReportComponent } from './schoolwide-report/schoolwide-report.component';
import { schoolwideReportResolver } from './schoolwide-report/schoolwide-report.resolver';
import { AppCommunicationsReportResolver } from './communications-report/communications-report.resolver';
import { AppCommunicationsReportTable } from './communications-report/communications-report-table/communications-report-table.component';
import { AppComplianceListComponent } from '../compliance/compliance-list/compliance-list.component';
import { AppComplianceListResolver } from '../compliance/compliance-list/compliance-list.resovler';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppDailyActivityReportTable } from './daily-activity-report/daily-activity-report-table/daily-activity-report-table.component';

/**
 * All of the report routes should be placed here
 * @path the url
 * @title
 * @resolve the resolver that gets data
 * @component the component displaying the report
 * @data -> Title The gray part of the title Subtitle -> the blue part of the title
 */

export const AppReportRoutes: ICustomRoute[] = [
  {
    path: 'daily-activity',
    title: 'Daily Activity Report',
    resolve: { data: dailyActivityReportResolver },
    component: AppDailyActivityReportTable,
    data: {
      title: 'Report: ',
      subtitle: 'Daily Activity',
      pageHeaderClass: 'report-page-header',
      buttons: [
        {
          name: 'Export',
          action: HeaderButtonAction.EXPORT,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'accrued',
    title: 'Accrued Records',
    resolve: { data: accruedReportResolver },
    component: AppAccruedReportComponent,
    data: {
      title: 'Report: ',
      subtitle: 'Accrued Records',
      pageHeaderClass: 'report-page-header',
      buttons: [
        {
          name: 'Export',
          action: HeaderButtonAction.EXPORT,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'school-activity',
    title: 'School Activity',
    resolve: { data: schoolActivityReportResolver },
    component: AppSchoolActivityReportComponent,
    data: {
      title: 'Report: ',
      subtitle: 'School Activity',
      pageHeaderClass: 'report-page-header',
      buttons: [
        {
          name: 'Export',
          action: HeaderButtonAction.EXPORT,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'schoolwide-points',
    title: 'Schoolwide Points',
    resolve: { data: schoolwideReportResolver },
    component: AppSchoolwideReportComponent,
    data: {
      title: 'Report: ',
      subtitle: 'Schoolwide Points',
      pageHeaderClass: 'report-page-header',
      buttons: [
        {
          name: 'Export',
          action: HeaderButtonAction.EXPORT,
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'communications-report',
    title: 'Communications Report',
    component: AppCommunicationsReportTable,
    resolve: { data: AppCommunicationsReportResolver },
    data: {
      title: 'Report: ',
      subtitle: 'Communications Report',
      pageHeaderClass: 'report-page-header'
    }
  },
  {
    path: 'compliance',
    title: 'Compliance Report',
    component: AppComplianceListComponent,
    resolve: { data: AppComplianceListResolver },
    data: {
      title: 'Report: ',
      subtitle: 'Compliance',
      pageHeaderClass: 'report-page-header',
      buttons: [
        {
          name: 'Export',
          action: HeaderButtonAction.EXPORT,
          style: 'secondary-button'
        }
      ]
    }
  }
];
