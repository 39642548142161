
<app-common-table
  [getColumnValue]="getColumnValue"
  [getColumnTitle]="getColumnTitle"
  [showPagination]="showPagination"
  [tableFilters]="tableFilters"
  [columns]="columns"
  [templates]="templates"
  [dataArr]="dataArr">
</app-common-table>

<ng-template #tooltipHeader let-message="message" let-title="title">
  <div [matTooltip]="message" matTooltipPosition="above" class="header-tooltip">
    <span >{{title}}</span>
    <fa-icon [icon]="['fas', 'circle-info']"></fa-icon>
  </div>
</ng-template>

<ng-template #pointsColumn let-value="value">
  <b class="points">{{value}}</b>
</ng-template>
