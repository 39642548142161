<div class="container">
  <app-accrued-report-filters
  [loadingItems]="isLoading"
  [filters]="filters"
  (filtersUpdated)="onFiltersUpdated($event)"
  (applyFilters)="applyFilters()">
  </app-accrued-report-filters>
  <app-accrued-report-summary-card
    [studentsCount]="studentsCount"
    [totalCount]="totalCount">
  </app-accrued-report-summary-card>
  <app-accrued-report-actions
    (filtersUpdated)="onInstantFiltersUpdated($event)"
    [filters]="filters">
  </app-accrued-report-actions>
  <app-accrued-report-table
    *ngIf="!!dataArr.length && !isLoading"
    [showPagination]="showPagination"
    [tableFilters]="tableFilters"
    [dataArr]="dataArr">
  </app-accrued-report-table>

  <mat-progress-bar
    *ngIf="isLoading"
    class="app-loading-bar"
    color="primary"
    mode="indeterminate">
  </mat-progress-bar>

  <span *ngIf="!(dataArr.length || isLoading)">
    There are no records to show
  </span>
</div>
