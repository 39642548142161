"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Helpers = void 0;
class Helpers {
    /**
     * Debounce function to reduce the frequency of event handling
     * @param {func} func
     * @param {number} delay
     * @returns a debounced function
     */
    static debounce(func, delay) {
        let timeout;
        return (...args) => {
            clearTimeout(timeout);
            timeout = setTimeout(() => {
                func(...args);
            }, delay);
        };
    }
}
exports.Helpers = Helpers;
