import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  FormControl,
  Validators,
} from '@angular/forms';
import { NgForOf, NgIf } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectChange, MatSelectModule } from '@angular/material/select';
import { BaseComponent } from '../base-classes/base.component';
import { USBDeviceOption } from './printer.interface';
import { PrinterService } from './printer.service';


@Component({
  standalone: true,
  selector: 'app-printer-selector-modal',
  templateUrl: './printer-selector-modal.template.html',
  styleUrls: ['./printer-selector-modal.scss'],
  imports: [NgForOf, NgIf, MatButtonModule, MatSelectModule],
})
export class AppPrinterSelectorModal
  extends BaseComponent
  implements OnInit
{
  private selectUsbDevice: (productName: string) => void;
  private selectedDeviceProductName: string = null;
  public shouldSelectDevice: boolean = false;
  public allowConnectUsbDevice: () => void;
  public title: string = 'Select Printer';
  public onClose: () => void;
  public isLoading = true;

  constructor(
    public dialogRef: MatDialogRef<AppPrinterSelectorModal>,
    private printerService: PrinterService,
    @Inject(MAT_DIALOG_DATA)
    public data: {
      onClose: () => void;
      selectUsbDevice: (productName: string) => void;
      allowConnectUsbDevice: () => void;
    }
  ) {
    super();
    this.onClose = data.onClose;
    this.selectUsbDevice = data.selectUsbDevice;
    this.allowConnectUsbDevice = data.allowConnectUsbDevice;
  }

  get deviceOptions(): USBDeviceOption[] {
    return this.printerService.usbDeviceOptions;
  }

  public setSelectedDevice(event: MatSelectChange): void {
    this.selectedDeviceProductName = event.value;
  }

  public async ngOnInit() {
    this.isLoading = true;
    await this.printerService.loadDevices();
    this.shouldSelectDevice = !!this.deviceOptions.length;
    this.isLoading = false;
  }

  public async saveSelectedDevice(): Promise<void> {
    if (this.selectedDeviceProductName) {
      this.selectUsbDevice(this.selectedDeviceProductName);
      this.dialogRef.close();
    } else {
      // error
    }
  }

  public handleAllowConnectUsbDevice(): void {
    this.allowConnectUsbDevice();
    this.dialogRef.close();
  }

  handleClickHereKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Enter' || event.key === ' ') {
      event.preventDefault(); // Prevent the default action to avoid scrolling when space is pressed
      this.allowConnectUsbDevice();
    }
  }
}
