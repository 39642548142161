<form [formGroup]="roleForm" novalidate>
  <div class="role-details-container">
    <mat-card appearance="outlined">
      <mat-card-header>
        <mat-card-title class="card-title">Role Details</mat-card-title>
      </mat-card-header>
      <mat-card-content class="role-details">
        <mat-form-field>
          <mat-label> Name </mat-label>
          <input matInput placeholder="Role Name" formControlName="name" />
          <app-error-display
            controlName="name"
            [form]="roleForm"></app-error-display>
        </mat-form-field>
        <mat-form-field>
          <!-- Role school selection -->
          <mat-label> School </mat-label>
          <mat-select
            placeholder="School"
            id="school"
            formControlName="schoolId">
            <mat-option
              *ngFor="let school of schools"
              [value]="school.value"
              options-id="school"
              [option]="constantize(school.display)">
              {{ school.display }}
            </mat-option>
          </mat-select>
          <app-error-display
            controlName="schoolId"
            [form]="roleForm"></app-error-display>
        </mat-form-field>

        <mat-form-field>
          <mat-label> User Type </mat-label>
          <!-- Role privilege multi selection -->
          <mat-select placeholder="User Type" formControlName="userType">
            <mat-option
              *ngFor="let userType of userTypeOptions"
              [value]="userType.value"
              options-id="userType"
              [option]="constantize(userType.display)">
              {{ userType.display }}
            </mat-option>
          </mat-select>
          <app-error-display
            controlName="userType"
            [form]="roleForm"></app-error-display>
        </mat-form-field>

        <mat-form-field class="full-width-input">
          <mat-label>Behavior Codes Types</mat-label>
          <mat-select formControlName="behaviorCodeTypes" multiple>
            <mat-option
              *ngFor="let type of behaviorCodeTypeOptions"
              [value]="type.value"
              options-id="behaviorCodeTypes"
              [option]="constantize(type.display)">
              {{type.display}}
            </mat-option>
          </mat-select>
          <app-error-display
            [form]="roleForm"
            controlName="behaviorCodeTypes"></app-error-display>
        </mat-form-field>
      </mat-card-content>
    </mat-card>
  </div>
</form>
<div class="role-privileges-container twoColumnLayout">
  <div class="column">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="card-title">Privileges</mat-card-title>
      </mat-card-header>
      <mat-card-content>
        <mat-list>
          <div
            *ngFor="let category of privilegeCategoryOptions"
            [ngClass]="['privilege-category', category.value === selectedPrivilegeCategory  ? 'selected-category' : '']"
            (click)="onCategoryChange(category.value)"
            (keydown.enter)="onCategoryChange(category.value)">
            {{ category.display }} <fa-icon [icon]="faChevronRight"></fa-icon>
          </div>
        </mat-list>
      </mat-card-content>
    </mat-card>
  </div>
  <div class="column-right">
    <mat-card>
      <mat-card-header>
        <mat-card-title class="card-title"
          >{{ selectedPrivilegeCategory }}</mat-card-title
        >
      </mat-card-header>
      <mat-card-content class="privilege-category-option-content">
        <span class="privilege-category-option">
          <mat-checkbox
            [attr.options-id]="'Select All '+selectedPrivilegeCategory"
            class="example-margin"
            [checked]="categoryIsChecked()"
            (change)="setAllCategory($event.checked)">
            Select All {{selectedPrivilegeCategory}}
          </mat-checkbox>
        </span>
        <div *ngFor="let section of sectionsToDisplay()">
          <span class="privilege-category-option">
            <mat-checkbox
              [attr.options-id]="'Section ' + section.name"
              class="example-margin"
              [checked]="sectionIsChecked(section.name)"
              (change)="setAllSection($event.checked, section.name)">
              {{section.name}}
            </mat-checkbox>
          </span>
          <span class="example-list-section">
            <ul>
              <li *ngFor="let privilege of section.privileges">
                <mat-checkbox
                  [matTooltip]="privilege.description"
                  matTooltipPosition="above"
                  [attr.options-id]="privilege.display"
                  [(ngModel)]="privilege.checked">
                  {{privilege.display}}
                </mat-checkbox>
              </li>
            </ul>
          </span>
        </div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
