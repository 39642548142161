import { PrivilegeEnum, UserTypeEnum } from '@whetstoneeducation/hero-common';
import { IconName } from '@fortawesome/free-regular-svg-icons';
import { ThemePalette } from '@angular/material/core';

/**
 * Fields needed to create a button in the page header component
 */
export interface IHeaderButton {
  name: string;
  style?: string;
  icon?: IconName;
  privilege?: PrivilegeEnum;
  privileges?: PrivilegeEnum[];
  userType?: UserTypeEnum;
  userTypes?: UserTypeEnum[];
  route?: string;
  isExternalRoute?: boolean;
  normalizedRoute?: string;
  action?: HeaderButtonAction;
  queryParamsHandling?: 'preserve' | 'merge';
  requiresPrinter?: boolean;
  dropdownOptions?: IHeaderButton[];
  color?: ThemePalette;
}

export enum HeaderButtonAction {
  SAVE = 'save',
  CANCEL = 'cancel',
  ALTERNATE_SAVE = 'alternate_save',
  EDIT_SUBSCRIPTION = 'edit_subscription',
  ARCHIVE = 'archive',
  DELETE = 'delete',
  RESTORE = 'restore',
  CREATE = 'create',
  SHARE = 'share',
  APPROVE = 'approve',
  REJECT = 'reject',
  CLEAR = 'clear',
  CLONE = 'clone',
  PRINT = 'print',
  VIEW_ALL = 'view_all',
  VIEW = 'view',
  EXPORT = 'export',
  EXPORT_TABLE = 'exportTable',
  IMPORT = 'import',
  UPDATE = 'update',
  RESOURCES = 'resources',
  COLLABORATE = 'collaborate',
  OPEN_INFO_POPUP = 'open_info_popup',
  GOTO_DIRECTORY = 'gotoDirectory',
  OBSERVE = 'observe',
  VIEW_COMMENTS = 'viewComments',
  TOGGLE_VIEW = 'toggleView',
  LIST_ACCOUNTS = 'list-accounts',
  LIST_SETTINGS = 'list-settings',
  TRACK_STUDENTS = 'track-students',
  NAVIGATE = 'navigate',
  PRINT_STUDENT_INFO = 'print-student-info',
  BACK = 'back',
  RESET_PASSWORD = 'reset-password',
  DEACTIVATE_RECORDS = 'deactivate_records',
  ADD_STUDENTS = 'add-students',
  EDIT = 'edit'
}
