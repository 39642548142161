import { ICustomRoute } from '../../app-routing.module';
import { AuthGuard } from '../../route-guards/auth.guard';
import { AppStudentCreateEditComponent } from './students-create-edit/students-create-edit.component';
import { AppStudentCreateEditResolver } from './students-create-edit/students-create-edit.resolver';
import { HeaderButtonAction } from '../../shared/page-header/header-button';
import { AppStudentsListComponent } from './students-list/students-list.component';
import { AppStudentsListResolver } from './students-list/students-list.resolver';
import { AppStudentProfile } from './profile/student-profile.component';
import { AppStudentProfileResolver } from './profile/student-profile.resolver';
import { PrivilegeEnum, UserTypeEnum } from '@whetstoneeducation/hero-common';

export const AppStudentsRoutes: ICustomRoute[] = [
  {
    path: 'students/:id',
    title: 'Student',
    component: AppStudentCreateEditComponent,
    resolve: { data: AppStudentCreateEditResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Edit Student',
      buttons: [
        {
          name: 'Save',
          style: 'secondary-button',
          action: HeaderButtonAction.SAVE
        }
      ],
      breadcrumbs: [
        {
          path: 'students',
          title: 'Students'
        }
      ]
    }
  },
  {
    path: 'students',
    title: 'Students',
    component: AppStudentsListComponent,
    resolve: { data: AppStudentsListResolver },
    canActivate: [AuthGuard],
    data: {
      title: 'Students',
      buttons: [
        {
          name: 'Add',
          normalizedRoute: 'students/new',
          icon: 'plus-circle',
          style: 'secondary-button'
        }
      ]
    }
  },
  {
    path: 'students/:id/profile',
    title: 'Student Profile',
    component: AppStudentProfile,
    canActivate: [AuthGuard],
    resolve: { data: AppStudentProfileResolver },
    data: {
      title: 'Student Profile',
      breadcrumbsUserType: [UserTypeEnum.GUARDIAN],
      breadcrumbsUserTypeCondition: 'not',
      breadcrumbs: [
        {
          path: 'students',
          title: 'Students'
        },
        {
          path: '',
          title: 'Profile'
        }
      ],
      buttons: [
        {
          name: '',
          icon: 'pen',
          style: 'edit-student-button',
          action: HeaderButtonAction.UPDATE,
          privilege: PrivilegeEnum.MANAGE_STUDENTS
        },
        {
          name: 'TRACK STUDENT',
          style: 'secondary-button header-action-button',
          action: HeaderButtonAction.TRACK_STUDENTS,
          privilege: PrivilegeEnum.MANAGE_COMPLIANCE
        },
        {
          name: 'PRINT INFO',
          style: 'secondary-button header-action-button',
          action: HeaderButtonAction.PRINT_STUDENT_INFO,
          requiresPrinter: true,
          userTypes: [
            UserTypeEnum.GROUP_ADMIN,
            UserTypeEnum.SCHOOL,
            UserTypeEnum.INTERNAL_ADMIN,
            UserTypeEnum.TEACHER
          ]
        }
      ]
    }
  },
  {
    path: 'dashboard/student',
    title: 'Student Dashboard',
    component: AppStudentProfile,
    canActivate: [AuthGuard],
    resolve: { data: AppStudentProfileResolver }
  }
];
