import { Injectable } from '@angular/core';
import { AppSettingsService } from 'src/app/shared/services/settings.service';
import { AppWindowService } from 'src/app/shared/services/app-window.service';
import { BaseService } from 'src/app/shared/base-classes/base.service';
import { AppAuthService } from '../../pages/auth/auth.service';
import { AppHomeService } from '../../pages/home/home.service';
import { NavbarActions } from './navbar-models/navbar-actions.enum';
import { INavbarConfig } from './navbar-models/navbar-config.interface';
import {
  IMatNavbarItem,
  INavbarItem
} from './navbar-models/navbar-main-item.interface';
import { AppPrivilegesService } from '../../pages/auth/privileges.service';
import { PrivilegeEnum, UserTypeEnum } from '@whetstoneeducation/hero-common';
import { PrinterService } from '../printer/printer.service';

/**
 * Service used to get the navbar config data used to generate our navbar
 */
@Injectable({
  providedIn: 'root'
})
export class AppNavbarConfigService extends BaseService {
  /**
   * Default Constructor
   */
  constructor(
    public settingsService: AppSettingsService,
    public authService: AppAuthService,
    public window: AppWindowService,
    public privilegesService: AppPrivilegesService,
    private homeService: AppHomeService,
    private printerService: PrinterService
  ) {
    super({
      authService,
      settingsService,
      privilegesService
    });
  }

  /**
   * Get a config that tells us what navbar content we need
   */
  public async getNavbarConfig(): Promise<INavbarConfig> {
    // Get all of the data used to render our navbar
    const {} = await this.getNavbarData();

    // Get all of the navbar items we might want to see
    const leftNavbarItems = await this.getLeftNavbarItems();
    const rightNavbarItems = this.getRightNavbarItems();

    // Filter out all disabled items, or empty dropdowns
    return {
      leftNavbar: leftNavbarItems,
      rightNavbar: rightNavbarItems
    };
  }

  /**
   * Get all of the data used to render our navbar
   */
  public async getNavbarData(): Promise<{}> {
    // Make all of our API calls in our parallel

    return {};
  }

  /**
   * Fix a nav item list. Remove any disabled items, empty list, or useless dividers
   */
  public fixNavItemList(navItems: IMatNavbarItem[]): IMatNavbarItem[] {
    return (
      navItems
        // Remove empty child items
        .map((navItem) => {
          if (navItem.dropdownItems) {
            navItem.dropdownItems = this.fixNavItemList(navItem.dropdownItems);
            return navItem;
          } else {
            return navItem;
          }
        })
        .filter((navItem) => {
          if (navItem.dropdownItems) {
            // Filter out empty dropdown buttons
            return navItem.dropdownItems.length > 0;
          }
        })
    );
  }

  /**
   * Get the navbar items for the left side
   */
  public async getLeftNavbarItems(): Promise<IMatNavbarItem[]> {
    const homepage = await this.homeService.getHome();

    const navItems: IMatNavbarItem[] = [
      {
        text: 'Dashboard',
        action: NavbarActions.GO_HOME,
        enabled: !this.isUserType([UserTypeEnum.GUARDIAN]),
        ariaLabel: 'Dashboard'
      },
      {
        text: 'Behavior',
        route: '/behavior',
        dropdownItems: [
          {
            text: 'Students',
            route: '/students',
            enabled: true
          },
          {
            text: 'Custom Rosters',
            route: '/custom-rosters',
            enabled: this.hasSchoolSet()
          }
        ],
        enabled:
          this.hasSchoolSet() &&
          !this.isUserType([UserTypeEnum.STUDENT, UserTypeEnum.GUARDIAN])
      },
      {
        text: 'Student Groups',
        route: '/student-groups',
        enabled:
          this.hasSchoolSet() &&
          !this.isUserType([UserTypeEnum.STUDENT, UserTypeEnum.GUARDIAN])
      },
      // {
      //   text: 'Students',
      //   route: '/student-profile'
      // },
      // {
      //   text: 'Events',
      //   route: '/events'
      // },
      {
        text: 'Reports',
        route: '/reports',
        enabled:
          this.hasSchoolSet() &&
          (this.hasPrivilege(PrivilegeEnum.VIEW_DAILY_ACTIVITY) ||
            this.hasPrivilege(PrivilegeEnum.MANAGE_COMPLIANCE)),
        dropdownItems: [
          {
            text: 'Schoolwide Points',
            route: '/schoolwide-points',
            enabled: true
          },
          {
            text: 'School Activity',
            route: '/school-activity',
            enabled: true
          },
          {
            text: 'Daily Activity',
            route: '/daily-activity',
            enabled: this.hasPrivilege(PrivilegeEnum.VIEW_DAILY_ACTIVITY)
          },
          {
            text: 'Compliance',
            route: '/compliance',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_COMPLIANCE)
          },
          {
            text: 'Accrued',
            route: '/accrued',
            enabled: true
          },
          {
            text: 'Communications',
            route: '/communications-report',
            enabled: this.hasPrivilege(PrivilegeEnum.VIEW_COMMUNICATIONS_REPORT)
          }
        ]
      },
      {
        text: 'Config',
        route: '/config',
        enabled: !this.isUserType([
          UserTypeEnum.STUDENT,
          UserTypeEnum.GUARDIAN
        ]),
        dropdownItems: [
          {
            text: 'Users',
            route: '/users',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_USERS)
          },
          {
            text: 'Schools',
            route: '/schools',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_SCHOOLS)
          },
          {
            text: 'School Groups',
            route: '/school-groups',
            enabled: this.isUserType([UserTypeEnum.INTERNAL_ADMIN])
          },
          {
            text: 'Behavior Codes',
            route: '/behavior-codes',
            enabled: this.hasAnyPrivilege([
              PrivilegeEnum.MANAGE_BEHAVIOR_CODE,
              PrivilegeEnum.EDIT_BEHAVIOR_CODE_CONSEQUENCES
            ])
          },
          {
            text: 'Bell Schedules',
            route: '/bell-schedules',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_BELL_SCHEDULE)
          },
          {
            text: 'Reactions',
            route: '/reactions',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_COMPLIANCE)
          },
          {
            text: 'Interventions',
            route: '/interventions',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_BELL_SCHEDULE)
          },
          {
            text: 'Roles',
            route: '/roles',
            enabled:
              this.hasPrivilege(PrivilegeEnum.MANAGE_ROLE) &&
              this.hasSchoolGroupSet()
          },
          {
            text: 'Imports',
            route: '/import/summary',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_SFTP)
          },
          {
            text: 'Notification Templates',
            route: '/notification-templates',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_NOTIFICATION)
          },
          {
            text: 'Imports/Exports',
            route: '/imports-exports',
            enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_SFTP)
          },
          {
            text: 'Select USB Device',
            action: NavbarActions.SELECT_USB_DEVICE,
            enabled: this.printerService.usbAllowed
          }
        ]
      }
    ];

    return navItems;
  }

  /**
   * Get the navbar items for the right side
   */
  public getRightNavbarItems(): INavbarItem[] {
    const username = this.StorageManager.getLoggedInUserName();
    const schoolName = this.StorageManager.getLoggedInUser().currentSchool_name;
    return [
      {
        text: 'Guardian Dashboard',
        action: NavbarActions.GO_HOME,
        enabled: this.isUserType([UserTypeEnum.GUARDIAN])
      },
      {
        icon: 'language',
        action: NavbarActions.OPEN_LANGUAGES,
        size: 'xl',
        ariaLabel: 'Change language'
      },
      // {
      //   icon: 'volume-up',
      //   action: NavbarActions.TOGGLE_VOLUME,
      //   enabled: !this.isUserType([
      //     UserTypeEnum.STUDENT,
      //     UserTypeEnum.GUARDIAN
      //   ]),
      //   ariaLabel: 'Change volume'
      // },
      {
        icon: 'calendar-days',
        route: '/calendar',
        enabled: this.hasPrivilege(PrivilegeEnum.MANAGE_CALENDAR)
      },
      {
        icon: 'user-large',
        text: username,
        bottomText: schoolName,
        ariaLabel: 'Account menu',
        dropdownItems: [
          {
            text: 'My Settings',
            route: '/my-settings',
            ariaLabel: 'My settings'
          },
          {
            icon: 'arrow-right-from-bracket',
            text: 'Logout',
            action: NavbarActions.LOGOUT,
            ariaLabel: 'Logout'
          }
        ]
      }
    ];
  }
}
