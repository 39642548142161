<div class="student-profile-container">
  <div *ngIf="showError()">Student not correctly assigned to this account.</div>
  <div *ngIf="!showError()">
    <div class="student-profile-header">
      <app-student-header-info
        [selectedImage]="profile.studentDetail.profilePhotoUrl"
        [studentId]="profile.studentDetail.id"
        [firstName]="profile.studentDetail.firstName"
        [lastName]="profile.studentDetail.lastName"
        [studentExtId]="profile.studentDetail.studentExtId"
        [grade]="profile.studentDetail.grade"
        [hideProfileImageSelector]="isStudent"
        [studentGroupName]="profile.studentDetail.studentGroup?.name">
        <app-points-bubble
          *ngIf="!isStudent"
          [points]="points"></app-points-bubble>
        <div class="student-today-schedule-bubble" *ngIf="isStudent">
          <div
            tabindex="0"
            role="complementary"
            [attr.aria-label]="'Today\'s Schedule: ' + (profile.studentDetail.courseDescription
              ? profile.studentDetail.courseDescription
              : 'No schedules for today')">
            <span>Today's Schedule:</span>
            <span *ngIf="!!profile.studentDetail.courseDescription"
              >{{profile.studentDetail.courseDescription}}</span
            >
            <span *ngIf="!profile.studentDetail.courseDescription"
              >No Schedules for Today</span
            >
          </div>
          <fa-icon [icon]="bellIcon" size="lg"></fa-icon>
        </div>
      </app-student-header-info>
    </div>
    <div *ngIf="!!profile">
      <app-student-info-tabs [profile]="profile"> </app-student-info-tabs>
    </div>
  </div>
</div>
