import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  Output
} from '@angular/core';
import {
  IBehaviorEntryDailyActivityReportFilterDto,
  IDisplayData,
  ImportSubject
} from '@whetstoneeducation/hero-common';
import { IBehaviorCodesListFilters } from '../../../behavior-codes/behavior-codes-list/behavior-codes-list-models/behavior-codes-list-filters.interface';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { MatOptionSelectionChange } from '@angular/material/core';
import { map, Observable, startWith, Subject, takeUntil } from 'rxjs';
import { FormControl } from '@angular/forms';
import dayjs from 'dayjs';

@Component({
  selector: 'app-daily-activity-report-filters',
  templateUrl: './daily-activity-report-filter.template.html',
  styleUrls: ['./daily-activity-report-filter.scss']
})
export class AppDailyActivityReportFiltersComponent
  extends BaseComponent
  implements OnDestroy
{
  @Input() filters: IBehaviorEntryDailyActivityReportFilterDto;
  @Input() userOptions: IDisplayData[];
  @Input() behaviorCodeOptions: IDisplayData[];
  @Input() startDate: Date;

  userControl = new FormControl('');
  behaviorCodeControl = new FormControl('');
  showDeactivatedRecordsControl = new FormControl(false);
  private destroy$ = new Subject<void>();

  filteredUserOptions: Observable<IDisplayData[]>;
  filteredBehaviorCodeOptions: Observable<IDisplayData[]>;

  @Output()
  filtersUpdated: EventEmitter<IBehaviorEntryDailyActivityReportFilterDto>;
  public constructor() {
    super();
    this.filtersUpdated = new EventEmitter<IBehaviorCodesListFilters>();
    this.filteredUserOptions = this.userControl.valueChanges.pipe(
      startWith(''),
      map((value) => this._filterUserOptions(value || ''))
    );
    this.filteredBehaviorCodeOptions =
      this.behaviorCodeControl.valueChanges.pipe(
        startWith(''),
        map((value) => this._filterBehaviorCodeOptions(value || ''))
      );
    this.showDeactivatedRecordsControl.valueChanges
      .pipe(takeUntil(this.destroy$))
      .subscribe(() => {
        this.filters.showDeactivatedRecords =
          this.showDeactivatedRecordsControl.value;
        this.filtersUpdated.emit(this.filters);
      });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onStartDateUpdated(event: MatDatepickerInputEvent<Date>) {
    // Set event value to the beginning of the day with dayjs
    this.filters.startDate = dayjs(event.target.value).startOf('day').toDate();
    this.filtersUpdated.emit(this.filters);
  }

  onEndDateUpdated(event: MatDatepickerInputEvent<Date>) {
    // Set event value to the end of the day with dayjs
    this.filters.endDate = dayjs(event.target.value).endOf('day').toDate();
    this.filtersUpdated.emit(this.filters);
  }

  handleUserSelection(event: MatOptionSelectionChange) {
    const value = event.source.value;
    this.filters.userId = value;
    this.filtersUpdated.emit(this.filters);
    const user = this.userOptions.find((option) => option.value === value);
    this.userControl.setValue(user.display);
  }

  handleBehaviorCodeSelection(event: MatOptionSelectionChange) {
    const value = event.source.value;
    this.filters.behaviorCodeId = value;
    this.filtersUpdated.emit(this.filters);
    const user = this.behaviorCodeOptions.find(
      (option) => option.value === value
    );
    this.behaviorCodeControl.setValue(user.display);
  }

  handleShowDeactivatedRecordsChange() {
    this.filters.showDeactivatedRecords =
      this.showDeactivatedRecordsControl.value;
    this.filtersUpdated.emit(this.filters);
  }

  private _filterUserOptions(value: string): IDisplayData[] {
    const filterValue = value.toLowerCase();
    return this.userOptions.filter((option) =>
      option.display.toLowerCase().includes(filterValue)
    );
  }

  private _filterBehaviorCodeOptions(value: string): IDisplayData[] {
    const filterValue = value.toLowerCase();
    return this.behaviorCodeOptions.filter((option) =>
      option.display.toLowerCase().includes(filterValue)
    );
  }

  protected readonly ImportSubject = ImportSubject;
}
