import { Component, Input } from '@angular/core';
import { SchoolActivityReportRowResponseDto } from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';

export enum SchoolActivityReportTableColumns {
  USER = 'User',
  BEHAVIORS_TRACKED = 'Behaviors Tracked',
  POINTS_ISSUED = 'Points Issued',
  REFERRALS = 'Referrals'
}

@Component({
  selector: 'app-school-activity-report-table',
  templateUrl: './school-activity-report-table.template.html',
  styleUrls: []
})
export class AppSchoolActivityReportTable {
  @Input() showPagination: boolean;
  @Input() dataArr: SchoolActivityReportRowResponseDto[];
  @Input() tableFilters: ITableFilters;

  public columns: typeof SchoolActivityReportTableColumns = SchoolActivityReportTableColumns;

  public constructor() {
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case SchoolActivityReportTableColumns.USER:
        return row.userName;
      case SchoolActivityReportTableColumns.BEHAVIORS_TRACKED:
        return row.behaviorsTracked || 0;
      case SchoolActivityReportTableColumns.POINTS_ISSUED:
        return row.pointsIssued || 0;
      case SchoolActivityReportTableColumns.REFERRALS:
        return row.referrals || 0;
      default:
        return null;
    }
  }
}
