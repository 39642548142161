<div class="student-profile-details profile-info" aria-label="Student Information" tabindex="0">
  <mat-card appearance="outlined" class="student-profile-detail" tabindex="0" aria-label="Student personal information">
    <mat-card-header class="button-card-header">
      <div class="header-title-container">
        <h2>Personal Information</h2>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Email: ' + (profile.userDetail.email || 'N/A')">
        <div class="student-profile-detail-label">Email:</div>
        <div class="student-profile-detail-value">
          {{profile.userDetail.email || 'N/A'}}
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Date of Birth: ' + (profile.studentDetail.birthDate || 'N/A')">
        <div class="student-profile-detail-label">Date of Birth:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.birthDate || 'N/A'}}ß
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Ethnicity: ' + (profile.studentDetail.ethnicGroup || 'N/A')">
        <div class="student-profile-detail-label">Ethnicity:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.ethnicGroup || 'N/A'}}
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Language: ' + (profile.studentDetail.studentLanguage || 'N/A')">
        <div class="student-profile-detail-label">Language:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.studentLanguage || 'N/A'}}
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Gender: ' + (profile.studentDetail.gender || 'N/A')">
        <div class="student-profile-detail-label">Gender:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.gender || 'N/A'}}
        </div>
      </div>
    </mat-card-content>
  </mat-card>
  <mat-card
    appearance="outlined"
    class="student-profile-detail column-center"
    tabindex="0"
    aria-label="Student contact information">
    <mat-card-header class="button-card-header">
      <div class="header-title-container">
        <h2>Contact Information</h2>
      </div>
    </mat-card-header>
    <mat-card-content>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Home Phone: ' + (profile.studentDetail.homePhone || 'N/A')">
        <div class="student-profile-detail-label">Home Phone:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.homePhone || 'N/A'}}
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Work Phone: ' + (profile.studentDetail.workPhone || 'N/A')">
        <div class="student-profile-detail-label">Work Phone:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.workPhone || 'N/A'}}
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Address: ' + (profile.studentDetail.address || 'N/A')">
        <div class="student-profile-detail-label">Address:</div>
        <div class="student-profile-detail-value">
          {{profile.studentDetail.address || 'N/A'}}
        </div>
      </div>
      <div class="student-profile-detail-row" tabindex="0" [attr.aria-label]="'Contact detailed address: ' + getStudentDetailedAddress()">
        <div class="student-profile-detail-label"></div>
        <div class="student-profile-detail-value" aria-label="Contact detailed address">
          {{getStudentDetailedAddress()}}
        </div>
      </div>
    </mat-card-content>
  </mat-card>
</div>

