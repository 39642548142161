import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BehaviorCodeEntryConfirmReactionListColumnsEnum } from '../behavior-code-entry-confirm-reaction-models/behavior-code-entry-confirm-reaction-list-columns.enum';
import {
  DateUtil,
  PrivilegeEnum,
  ReactionEntriesListResponseDto
} from '@whetstoneeducation/hero-common';
import { BaseTableComponent } from '../../../../shared/tables/base-table/base-table';
import { AppToastManagerService } from '../../../../shared/services/toast-manager.service';
import { ReactionPrintService } from '../../behavior-code-entry-reactions-printer/behavior-code-reaction-print.service';
import { PrinterService } from '../../../../shared/printer/printer.service';
import { AppPrivilegesService } from '../../../auth/privileges.service';

@Component({
  selector: 'app-behavior-code-entry-confirm-reaction-table',
  templateUrl: './behavior-code-entry-confirm-reaction-table.component.html',
  styleUrls: ['./behavior-code-entry-confirm-reaction-table.component.scss']
})
export class BehaviorCodeEntryConfirmReactionTableComponent extends BaseTableComponent<ReactionEntriesListResponseDto> {
  @Input() reloadTable: () => Promise<void>;
  @Output() editReaction = new EventEmitter<ReactionEntriesListResponseDto>();

  public get displayedColumns() {
    return Object.values(BehaviorCodeEntryConfirmReactionListColumnsEnum);
  }

  public columnValues = {
    [BehaviorCodeEntryConfirmReactionListColumnsEnum.STUDENT_NAME]: [
      'Student Name'
    ],
    [BehaviorCodeEntryConfirmReactionListColumnsEnum.REACTION]: ['Reaction'],
    [BehaviorCodeEntryConfirmReactionListColumnsEnum.REACTION_DATE]: [
      'Reaction Date'
    ],
    [BehaviorCodeEntryConfirmReactionListColumnsEnum.ACTIONS]: ['Actions']
  };

  public columns = BehaviorCodeEntryConfirmReactionListColumnsEnum;
  public showPrintReactionButton: boolean = false;

  constructor(
    public toastManager: AppToastManagerService,
    private reactionPrinter: ReactionPrintService,
    privilegesService: AppPrivilegesService,
    private printer: PrinterService
  ) {
    super({ privilegesService });
    this.printer.updater = () => {
      this.showPrintReactionButton = this.printer.usbAvailable;
    };
  }

  public async onEditReaction(
    reaction: ReactionEntriesListResponseDto
  ): Promise<void> {
    this.editReaction.emit(reaction);
  }

  public onPrintReaction(reaction: ReactionEntriesListResponseDto): void {
    this.reactionPrinter.setData(reaction).print();
  }

  protected readonly DateUtil = DateUtil;
  protected readonly PrivilegeEnum = PrivilegeEnum;
}
