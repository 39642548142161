import { AfterViewInit, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { debounceTime, distinctUntilChanged, fromEvent, map } from 'rxjs';
import { faBarsFilter } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-filter-results-input',
  templateUrl: './filter-results-input.template.html',
  styleUrls: ['./filter-results-input.scss']
})
export class AppFilterResultsInputComponent  implements AfterViewInit {
  public filterIcon = faBarsFilter;
  @ViewChild('searchInput') searchInput: ElementRef;

  @Output()
  private filterUpdated: EventEmitter<string | undefined> = new EventEmitter<string | undefined>();

  public constructor(
  ) {
  }
  ngAfterViewInit(): void {
    fromEvent(this.searchInput.nativeElement, 'input')
      .pipe(
        map((event: Event) => (event.target as HTMLInputElement).value),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((value?: string) => {
        this.filterUpdated.emit(value || undefined);
      });
  }
}
