<div class="list-filters-content">
  <mat-form-field class="input">
    <mat-label>BehaviorCode</mat-label>
    <input
      type="text"
      placeholder="BehaviorCode"
      matInput
      [formControl]="behaviorCodeControl"
      [matAutocomplete]="autoCodes" />
    <mat-autocomplete #autoCodes="matAutocomplete">
      <mat-option
        (onSelectionChange)="handleBehaviorCodeSelection($event)"
        *ngFor="let option of filteredBehaviorCodeOptions | async"
        [value]="option.value">
        {{option.display}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>User</mat-label>
    <input
      type="text"
      placeholder="User"
      matInput
      [formControl]="userControl"
      [matAutocomplete]="autoUsers" />
    <mat-autocomplete #autoUsers="matAutocomplete">
      <mat-option
        (onSelectionChange)="handleUserSelection($event)"
        *ngFor="let option of filteredUserOptions | async"
        [value]="option.value">
        {{option.display}}
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>

  <mat-form-field class="input">
    <mat-label>Start Date</mat-label>
    <input
      matInput
      [matDatepicker]="startDatePicker"
      type="text"
      [value]="startDate"
      (dateChange)="onStartDateUpdated($event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="startDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #startDatePicker></mat-datepicker>
  </mat-form-field>
  <mat-form-field class="input">
    <mat-label>End Date</mat-label>
    <input
      matInput
      [matDatepicker]="endDatePicker"
      type="text"
      (dateChange)="onEndDateUpdated($event)" />
    <mat-datepicker-toggle
      matSuffix
      [for]="endDatePicker"></mat-datepicker-toggle>
    <mat-datepicker #endDatePicker></mat-datepicker>
  </mat-form-field>

  <mat-slide-toggle
    [color]="'primary'"
    class="filter-item"
    [formControl]="showDeactivatedRecordsControl">
    Show Deactivated Records
  </mat-slide-toggle>
</div>
