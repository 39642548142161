"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransformConfig = void 0;
const dtos_1 = require("./dtos");
class TransformConfig {
    destination;
    sourceFields;
    transform;
    constructor(options) {
        this.destination = options.destination;
        this.sourceFields = options.sourceFields;
        this.transform = options.transform;
    }
    /**
     * Returns a function that applies the given transform to an array from the source object.
     * The returned function accepts a MappableBase object and returns a transformed array.
     *
     * @param {string} sourceField - The field in the source object containing the array to be transformed.
     * @param {(array: any[]) => any} transform - The transformation to be applied to the array.
     * @returns {(sourceObj: MappableBase) => any[]} - A function that applies the transform to the array from the source object.
     *
     * @example
     * // Example usage of transformArray
     * return [
     *   {
     *     destination: 'privileges',
     *     transform: MappableBase.transformArray(
     *       'roles',
     *       (sources: IRole[]): any => {
     *         let privileges: IPrivilege[] = [];
     *         // Iterate through each role in the sources array
     *         for (const role of sources) {
     *           // Check if the role has privileges and if it's an array
     *           if (role && role.privileges && Array.isArray(role.privileges))
     *             // Merge the privileges arrays
     *             privileges = [...privileges, ...role.privileges];
     *         }
     *         // Remove duplicates and return the privileges array
     *         return Array.from(new Set(privileges));
     *       }
     *     )
     *   },
     *   {
     *     destination: 'roles',
     *     transform: MappableBase.transformArray(
     *       'roles',
     *       (source: IRole[]): any => {
     *         // Map each role in the source array to a new object with specific properties
     *         return source.map((role) => {
     *           return {
     *             id: role.id,
     *             name: role.name,
     *             currentSchoolId: role.currentSchoolId
     *           };
     *         });
     *       }
     *     )
     *   }
     * ];
     */
    static transformArray(sourceField, transform) {
        return (innerSourceObj) => {
            if (innerSourceObj &&
                innerSourceObj[sourceField] &&
                Array.isArray(innerSourceObj[sourceField])) {
                const arraySource = innerSourceObj[sourceField];
                return transform(arraySource);
            }
            return [];
        };
    }
    static transformSchoolsToRelations() {
        return TransformConfig.transformArray('schools', (schools) => {
            return schools.map((school) => new dtos_1.RelationDto({ id: school.id, name: school.name }));
        });
    }
    static mapIdsFromSchools() {
        return TransformConfig.transformArray('schools', (source) => {
            return source.map((school) => { return school?.id; });
        });
    }
    static mapIdFromSchool() {
        return (source) => {
            if (source && source['school'] && source['school'].id) {
                return source['school'].id;
            }
            return null;
        };
    }
    static mapIdFromSchoolGroup() {
        return (source) => {
            if (source && source['schoolGroup'] && source['schoolGroup'].id) {
                return source['schoolGroup'].id;
            }
            return null;
        };
    }
    static transformTimestampToDate(sourceField) {
        return (source) => {
            if (source && source[sourceField]) {
                const timestamp = source[sourceField];
                return new Date(timestamp);
            }
            throw Error(`Invalid timestamp\nSource ${source}\nSourceField ${sourceField}`);
        };
    }
    static transformDateToTimestamp(sourceField) {
        return (source) => {
            if (source && source[sourceField]) {
                const date = source[sourceField];
                if (date instanceof Date)
                    return date.getTime();
                else
                    return null;
            }
            throw Error(`Invalid date\nSource ${source}\nSourceField ${sourceField}`);
        };
    }
}
exports.TransformConfig = TransformConfig;
