import { Component, Inject } from '@angular/core';
import {
  IImportFileError,
  SftpImportResponseDto
} from '@whetstoneeducation/hero-common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { AppSftpService } from '../sftp.service';
import { AppToastManagerService } from '../../../shared/services/toast-manager.service';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { BaseComponent } from '../../../shared/base-classes/base.component';

@Component({
  selector: 'app-import-view-dialog',
  templateUrl: './import-view-dialog.template.html',
  styleUrls: ['./import-view-dialog.scss']
})
export class AppImportViewDialogComponent extends BaseComponent {
  public isLoading = false;
  public import: SftpImportResponseDto;
  public importErrors: IImportFileError[];
  public importErrorHtml: SafeHtml;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: SftpImportResponseDto,
    public dialogRef: MatDialogRef<AppImportViewDialogComponent>,
    readonly sftpService: AppSftpService,
    readonly sanitizer: DomSanitizer,
    readonly toastService: AppToastManagerService
  ) {
    super();
    this.import = data;
    this.importErrors = JSON.parse(this.import.errorsReported);
  }

  viewErrorRecord(record: any) {
    // format record as a pretty print json string
    const ppJson = JSON.stringify(record, null, 2);
    // Create a new window
    const newWindow = window.open('', '_blank');

    // Check if the window was created successfully
    if (newWindow) {
      // Write the JSON content to the new window
      newWindow.document.title = 'Error Record';
      newWindow.document.open();
      newWindow.document.write(
        '<html lang="en"><body><pre>' + ppJson + '</pre></body></html>'
      );
      newWindow.document.close();
    } else {
      console.error('Failed to open new window');
    }
  }

  getRecordSize(record: any): string {
    // return size of record in bytes / kb
    const size = new Blob([JSON.stringify(record)]).size;
    // if size is less than 1kb, return size in bytes
    if (size < 1024) {
      return size + ' bytes';
    }
    // return size in kb
    return (size / 1024).toFixed(2) + ' kb';
  }

  getRecordPreview(record: any): string {
    // return a preview of the record
    return JSON.stringify(record).substring(0, 64);
  }

  async downloadImportReport(sftpImport: SftpImportResponseDto) {
    // download file as a string
    const response = await this.sftpService.downloadImportReport(
      sftpImport.sftpSettingsId
    );

    this.downloadFile(response, 'import-report.txt');
  }

  protected readonly JSON = JSON;
}
