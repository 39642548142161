<!-- Action Button -->
<button
  mat-flat-button
  *ngIf="button.action"
  (click)="emitHeaderEvent.emit(button.action || null)"
  [color]="button.color"
  [class]="button.style + ' standard-button'"
  options-id="HEADER_BUTTON"
  [option]="constantize(button.name)">
  <fa-icon *ngIf="button.icon" [icon]="['fas', button.icon]"></fa-icon>
  {{ button.name | uppercase }}
</button>

<!-- Internal Route Button -->
<button
  mat-flat-button
  *ngIf="button.normalizedRoute && !button.isExternalRoute"
  [routerLink]="button.normalizedRoute"
  [queryParamsHandling]="button.queryParamsHandling || null"
  [color]="button.color"
  [class]="button.style + ' standard-button'"
  options-id="HEADER_BUTTON"
  [option]="constantize(button.name)">
  <fa-icon *ngIf="button.icon" [icon]="['fas', button.icon]"></fa-icon>
  {{ button.name }}
</button>

<!-- External Route Button -->
<button
  mat-flat-button
  *ngIf="button.normalizedRoute && button.isExternalRoute"
  target="_blank"
  [color]="button.color"
  [class]="button.style + ' standard-button'"
  options-id="HEADER_BUTTON"
  [option]="constantize(button.name)">
  <fa-icon *ngIf="button.icon" [icon]="['fas', button.icon]"></fa-icon>
  <a [href]="button.normalizedRoute">{{ button.name }}</a>
</button>

<!-- Dropdown Button Group -->
<button
  mat-flat-button
  *ngIf="button.dropdownOptions"
  type="button"
  [color]="button.color"
  [class]="button.style + ' standard-button'"
  data-toggle="dropdown"
  aria-haspopup="true"
  aria-expanded="false"
  options-id="HEADER_BUTTON"
  [option]="constantize(button.name)">
  <fa-icon *ngIf="button.icon" [icon]="['fas', button.icon]"></fa-icon>
  {{ button.name }}
</button>
<div class="dropdown-menu">
  <ng-container *ngFor="let dropdownButton of button.dropdownOptions">
    <!-- Action Button -->
    <button
      *ngIf="dropdownButton.action"
      (click)="emitHeaderEvent.emit(dropdownButton.action || null)"
      [className]="dropdownButton.style + ' dropdown-item standard-button'"
      [options-id]="constantize(button.name) || constantize(button.action)"
      [option]="constantize(dropdownButton.name)">
      <i *ngIf="dropdownButton.icon" [className]="dropdownButton.icon"></i>
      {{ dropdownButton.name }}
    </button>

    <!-- Internal Route Button -->
    <a
      *ngIf="dropdownButton.normalizedRoute && !dropdownButton.isExternalRoute"
      [routerLink]="dropdownButton.normalizedRoute"
      [queryParamsHandling]="dropdownButton.queryParamsHandling || null"
      [className]="dropdownButton.style + ' dropdown-item standard-button'"
      [options-id]="constantize(button.name) || constantize(button.action)"
      [option]="constantize(dropdownButton.name)">
      <i *ngIf="dropdownButton.icon" [className]="dropdownButton.icon"></i>
      {{ dropdownButton.name }}
    </a>

    <!-- External Route Button -->
    <a
      *ngIf="dropdownButton.normalizedRoute && dropdownButton.isExternalRoute"
      [href]="dropdownButton.normalizedRoute"
      target="_blank"
      [className]="dropdownButton.style + ' dropdown-item standard-button'"
      [options-id]="constantize(button.name) || constantize(button.action)"
      [option]="constantize(dropdownButton.name)">
      <i *ngIf="dropdownButton.icon" [className]="dropdownButton.icon"></i>
      {{ dropdownButton.name }}
    </a>
  </ng-container>
</div>
