import { BaseComponent } from '../../../shared/base-classes/base.component';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output
} from '@angular/core';
import { ImportMappingConfiguration } from '@whetstoneeducation/hero-common';
import {
  faFile,
  faRefresh,
  faTrash,
  faUndo
} from '@fortawesome/free-solid-svg-icons';
import { MatSelectChange } from '@angular/material/select';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { validate } from 'class-validator';

@Component({
  selector: 'app-mapping-table',
  templateUrl: './mapping-table.template.html',
  styleUrls: ['./mapping-table.scss']
})
export class AppMappingTableComponent
  extends BaseComponent
  implements AfterViewInit, OnChanges
{
  selectedValues: any[] = [];
  @Input() public parsedFile: string[][] = [];
  @Input() public columnHeaders: string[] = [];
  @Input() public mappingConfig: ImportMappingConfiguration =
    new ImportMappingConfiguration();
  @Output() mappingConfigChange =
    new EventEmitter<ImportMappingConfiguration>();

  public transposedFile: {}[] = [];
  public headerMapping = {};
  public formGroup: FormGroup;
  public availableColumnsDict: { [index: number]: string[] } = {};
  constructor(private formBuilder: FormBuilder) {
    super();
  }
  ngOnInit() {
    this.selectedValues = new Array(this.transposedFile.length).fill(null); // assuming transposedFile has rows
  }
  ngOnChanges() {
    if (this.columnHeaders && this.columnHeaders.length > 0) {
      this.createFormGroup();
    }
  }

  createFormGroup() {
    const formControls = {};
    this.columnHeaders.forEach((header, index) => {
      formControls['fieldSelect' + index] = new FormControl();
      this.availableColumnsDict[index] = [
        ...this.mappingConfig.availableColumns
      ];
    });

    this.formGroup = this.formBuilder.group(formControls);
  }

  public async ngAfterViewInit() {
    this.transposeParsedFile();
    await this.populateHeaderMapping();
    Object.keys(this.headerMapping).forEach((key) => {
      if (this.headerMapping[key] !== undefined) {
        this.formGroup
          .get('fieldSelect' + key)
          .setValue(this.headerMapping[key]);
      }
    });
  }

  private async populateHeaderMapping(): Promise<void> {
    // populate select dropdowns with the current mapping
    const mappingErrors = await validate(this.mappingConfig);
    if (mappingErrors.length === 0 && this.mappingConfig.hasHeaderRow) {
      // map header row to column indices
      const headerRow = this.columnHeaders;
      headerRow.forEach((header, index) => {
        // set header index to the key of the mappingConfig.mapping that matches the value of the header
        const entry = Object.entries(this.mappingConfig.mapping).find(
          (entry) => entry[1] === header
        );
        if (entry) {
          this.headerMapping[index] = entry[0];
        }
      });
    } else {
      // header mapping is reversed key value value key from mappingConfig.mapping
      this.headerMapping = Object.keys(this.mappingConfig.mapping).reduce(
        (acc, key) => {
          acc[this.mappingConfig.mapping[key]] = key;
          return acc;
        },
        {}
      );
    }
  }

  private transposeParsedFile(): void {
    // Calculate the maximum row length
    const maxLength = this.parsedFile.reduce(
      (max, row) => Math.max(max, row.length),
      0
    );

    // Initialize a new array based on the maximum length (columns after transpose)
    const transposed = Array.from({ length: maxLength }, (_, colIndex) =>
      this.parsedFile.map((row) => row[colIndex] || '')
    );

    // Convert each row into an object
    this.transposedFile = transposed.map((row) => {
      const rowObject = {};
      ['data1', 'data2', 'data3', 'data4'].forEach((header, index) => {
        rowObject[header] = row[index];
      });
      return rowObject;
    });
  }

  public isRequiredField(column: string): boolean {
    return this.mappingConfig.requiredColumns.includes(column);
  }

  public updateMapping(
    position: number,
    header: string,
    event: MatSelectChange,
    columnIndex: number
  ) {
    let oldValue: string;

    // Find the old value based on the position or header
    if (this.mappingConfig.hasHeaderRow) {
      oldValue = Object.keys(this.mappingConfig.mapping).find(
        (key) => this.mappingConfig.mapping[key] === header
      );
      this.mappingConfig.mapping[event.value] = header;
    } else {
      oldValue = Object.keys(this.mappingConfig.mapping).find(
        (key) => this.mappingConfig.mapping[key] === position
      );
      this.mappingConfig.mapping[event.value] = position;
    }

    // If there's an old value, add it back to the available columns (other dropdowns)
    if (oldValue) {
      this.addSelectionToAvailableColumn(oldValue);
    }
    // Remove the new selected value from all other dropdowns
    this.removeSelectionFromAvailableColumn(event.value, columnIndex);
    this.mappingConfigChange.emit(this.mappingConfig);
  }

  public clearSelection(i: number, header: string, event: MouseEvent): void {
    // This stops the event from propagating to the parent (selectionChange)
    event.stopPropagation();

    const clearedValue = this.selectedValues[i];
    this.selectedValues[i] = null;

    if (clearedValue !== null && clearedValue !== undefined) {
      let mappingUpdated = false;

      if (this.mappingConfig.hasHeaderRow) {
        const key = Object.keys(this.mappingConfig.mapping).find(
          (key) => this.mappingConfig.mapping[key] === header
        );
        if (key) {
          const currentKeys = Object.keys(this.mappingConfig.mapping);
          const keysToKeep = currentKeys.filter((key) => key !== clearedValue);

          this.mappingConfig.mapping = keysToKeep.reduce((acc, key) => {
            acc[key] = this.mappingConfig.mapping[key];
            return acc;
          }, {});

          mappingUpdated = true;
        }
      }
      // Add the cleared value back to available columns for other dropdowns
      this.addSelectionToAvailableColumn(clearedValue);

      if (mappingUpdated) {
        this.mappingConfigChange.emit(this.mappingConfig);
      }
    }
  }

  public addSelectionToAvailableColumn(mappingValue: string) {
    if (mappingValue == null) return;
    // Loop through all the keys and add the value back to each one
    Object.keys(this.availableColumnsDict).forEach((key) => {
      const index = parseInt(key); // Convert key to integer (index)
      const columnArray = this.availableColumnsDict[index];

      // If the value is not already in the dropdown, add it
      if (!columnArray.includes(mappingValue)) {
        this.availableColumnsDict[index] = [mappingValue, ...columnArray];
      }
    });
    // Emit the updated configuration after modifying availableColumnsDict
    this.mappingConfigChange.emit(this.mappingConfig);
  }

  public removeSelectionFromAvailableColumn(
    mappingValue: string,
    currentColumnIndex: number
  ) {
    // Only proceed if mappingValue is valid (not null or undefined)
    if (mappingValue == null) return;

    if (Object.keys(this.mappingConfig.mapping).includes(mappingValue)) {
      Object.keys(this.availableColumnsDict).forEach((key) => {
        const index = parseInt(key);
        if (index === currentColumnIndex) {
          return;
        }
        this.availableColumnsDict[index] = this.availableColumnsDict[
          index
        ].filter((column) => column !== mappingValue);
      });
    } else {
      // If mappingValue doesn't exist, add it to the available columns (but not null values)
      Object.keys(this.availableColumnsDict).forEach((key) => {
        const index = parseInt(key);
        if (
          mappingValue &&
          !this.availableColumnsDict[index].includes(mappingValue)
        ) {
          this.availableColumnsDict[index] = [
            mappingValue,
            ...this.availableColumnsDict[index]
          ];
        }
      });
    }
  }

  protected readonly faUndo = faUndo;
  protected readonly faFile = faFile;
  protected readonly faTrash = faTrash;
  protected readonly faRefresh = faRefresh;
}
