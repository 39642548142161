import { Component, Input } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';

@Component({
  selector: 'app-accrued-report-summary-card',
  templateUrl: './accrued-report-summary-card.template.html',
  styleUrls: ['./accrued-report-summary-card.scss']
})
export class AppAccruedReportSummaryCard extends BaseComponent {

  @Input()
  public totalCount: number;
  @Input()
  public studentsCount: string;

  public constructor(
  ) {
    super();
  }
}
