"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PrivilegeDict = exports.PrivilegeCategory = exports.PrivilegeSection = void 0;
const enums_1 = require("../enums");
var PrivilegeSection;
(function (PrivilegeSection) {
    PrivilegeSection["BEHAVIOR_CODE"] = "Behavior Code";
    PrivilegeSection["BEHAVIOR_TRACKING"] = "Behavior Tracking";
    PrivilegeSection["BEll_SCHEDULE"] = "Bell Schedule";
    PrivilegeSection["CALENDAR"] = "Calendar";
    PrivilegeSection["COMPLIANCE"] = "Compliance";
    PrivilegeSection["INTEGRATIONS"] = "Integrations (imports/SFTP)";
    PrivilegeSection["NOTIFICATION"] = "Notification";
    PrivilegeSection["ROLE"] = "Role";
    PrivilegeSection["SCHOOL"] = "School";
    PrivilegeSection["STUDENT_GROUP"] = "Student Group";
    PrivilegeSection["USER"] = "User";
    PrivilegeSection["STUDENTS"] = "Students";
    PrivilegeSection["REACTIONS"] = "Reactions";
})(PrivilegeSection || (exports.PrivilegeSection = PrivilegeSection = {}));
var PrivilegeCategory;
(function (PrivilegeCategory) {
    PrivilegeCategory["CONFIGURATION"] = "Configuration";
    PrivilegeCategory["STUDENTS"] = "Students";
    PrivilegeCategory["REPORTS"] = "Reports";
    PrivilegeCategory["STUDENT_GROUPS"] = "Student Groups";
})(PrivilegeCategory || (exports.PrivilegeCategory = PrivilegeCategory = {}));
exports.PrivilegeDict = {
    [enums_1.PrivilegeEnum.DEFAULT]: {
        name: enums_1.PrivilegeEnum.DEFAULT,
        description: 'Default privilege level',
        display: 'Default',
        userTypes: [
            enums_1.UserTypeEnum.STUDENT,
            enums_1.UserTypeEnum.GUARDIAN,
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.VIEW_DAILY_ACTIVITY]: {
        name: enums_1.PrivilegeEnum.VIEW_DAILY_ACTIVITY,
        description: 'View daily activity',
        display: 'View Daily Activity',
        category: PrivilegeCategory.REPORTS,
        section: PrivilegeSection.COMPLIANCE,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.VIEW_COMMUNICATIONS_REPORT]: {
        name: enums_1.PrivilegeEnum.VIEW_COMMUNICATIONS_REPORT,
        description: 'View communications report',
        display: 'View Communications Report',
        category: PrivilegeCategory.REPORTS,
        section: PrivilegeSection.COMPLIANCE,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.TRACK_BEHAVIOR]: {
        name: enums_1.PrivilegeEnum.TRACK_BEHAVIOR,
        description: 'Track behavior',
        display: 'Track Behavior',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.BEHAVIOR_TRACKING,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.EDIT_TRACKED_BEHAVIOR]: {
        name: enums_1.PrivilegeEnum.EDIT_TRACKED_BEHAVIOR,
        description: 'Edit tracked behavior',
        display: 'Edit Tracked Behavior',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.BEHAVIOR_TRACKING,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.CREATE_BEHAVIOR_NOTES]: {
        name: enums_1.PrivilegeEnum.CREATE_BEHAVIOR_NOTES,
        description: 'Write behavior notes',
        display: 'Write behavior notes',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.BEHAVIOR_TRACKING,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.EDIT_BEHAVIOR_NOTES]: {
        name: enums_1.PrivilegeEnum.EDIT_BEHAVIOR_NOTES,
        description: 'Edit behavior notes',
        display: 'Edit behavior notes',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.BEHAVIOR_TRACKING,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.VIEW_BEHAVIOR_NOTES]: {
        name: enums_1.PrivilegeEnum.VIEW_BEHAVIOR_NOTES,
        description: 'View behavior notes',
        display: 'View behavior notes',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.BEHAVIOR_TRACKING,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.DELETE_TRACKED_BEHAVIOR]: {
        name: enums_1.PrivilegeEnum.DELETE_TRACKED_BEHAVIOR,
        description: 'Delete tracked behavior',
        display: 'Delete Tracked Behavior',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.BEHAVIOR_TRACKING,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_BEHAVIOR_CODE]: {
        name: enums_1.PrivilegeEnum.MANAGE_BEHAVIOR_CODE,
        description: 'Manage behavior code',
        display: 'Manage Behavior Code',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.BEHAVIOR_CODE,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_BELL_SCHEDULE]: {
        name: enums_1.PrivilegeEnum.MANAGE_BELL_SCHEDULE,
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.BEll_SCHEDULE,
        description: 'Manage bell schedule',
        display: 'Manage Bell Schedule',
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.SET_ACTIVE_BELL_SCHEDULE]: {
        name: enums_1.PrivilegeEnum.SET_ACTIVE_BELL_SCHEDULE,
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.BEll_SCHEDULE,
        description: 'Set active bell schedule',
        display: 'Set Active Bell Schedule',
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_CALENDAR]: {
        name: enums_1.PrivilegeEnum.MANAGE_CALENDAR,
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.CALENDAR,
        description: 'Manage calendar',
        display: 'Manage Calendar',
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_SFTP]: {
        name: enums_1.PrivilegeEnum.MANAGE_SFTP,
        description: 'Manage SFTP',
        display: 'Manage SFTP',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.INTEGRATIONS,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_IMPORT_EXPORT]: {
        name: enums_1.PrivilegeEnum.MANAGE_IMPORT_EXPORT,
        description: 'Manage import export',
        display: 'Manage Import/Export',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.INTEGRATIONS,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_NOTIFICATION]: {
        name: enums_1.PrivilegeEnum.MANAGE_NOTIFICATION,
        description: 'Manage notification',
        display: 'Manage Notification',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.NOTIFICATION,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_COMPLIANCE]: {
        name: enums_1.PrivilegeEnum.MANAGE_COMPLIANCE,
        description: 'Manage compliance',
        display: 'Manage Compliance',
        category: PrivilegeCategory.REPORTS,
        section: PrivilegeSection.COMPLIANCE,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_ROLE]: {
        name: enums_1.PrivilegeEnum.MANAGE_ROLE,
        description: 'Manage role',
        display: 'Manage Role',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.ROLE,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.INTERNAL_ADMIN]: {
        name: enums_1.PrivilegeEnum.INTERNAL_ADMIN,
        description: 'Internal admin',
        display: 'Internal Admin',
        userTypes: [enums_1.UserTypeEnum.INTERNAL_ADMIN]
    },
    [enums_1.PrivilegeEnum.MANAGE_SCHOOLS]: {
        name: enums_1.PrivilegeEnum.MANAGE_SCHOOLS,
        description: 'Edit schools',
        display: 'Manage Schools',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.SCHOOL,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.VIEW_STUDENT_GROUPS]: {
        name: enums_1.PrivilegeEnum.VIEW_STUDENT_GROUPS,
        description: 'View student groups',
        category: PrivilegeCategory.STUDENT_GROUPS,
        display: 'View Student Groups',
        section: PrivilegeSection.STUDENT_GROUP,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_STUDENT_GROUPS]: {
        name: enums_1.PrivilegeEnum.MANAGE_STUDENT_GROUPS,
        description: 'Manage student groups',
        display: 'Manage Student Groups',
        category: PrivilegeCategory.STUDENT_GROUPS,
        section: PrivilegeSection.STUDENT_GROUP,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_STUDENTS]: {
        name: enums_1.PrivilegeEnum.MANAGE_STUDENTS,
        description: 'Manage students',
        display: 'Manage Students',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.STUDENTS,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.MANAGE_USERS]: {
        name: enums_1.PrivilegeEnum.MANAGE_USERS,
        description: 'Manage users',
        display: 'Manage Users',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.USER,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.EDIT_BEHAVIOR_CODE_CONSEQUENCES]: {
        name: enums_1.PrivilegeEnum.EDIT_BEHAVIOR_CODE_CONSEQUENCES,
        description: 'Only Edit Behavior Code Reactions, Interventions and Notifications',
        display: 'Edit Behavior Code Consequences',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.BEHAVIOR_CODE,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.TRIGGER_MIGRATION]: {
        name: enums_1.PrivilegeEnum.TRIGGER_MIGRATION,
        description: 'Trigger migration',
        display: 'Trigger Migration',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.INTEGRATIONS,
        userTypes: [enums_1.UserTypeEnum.INTERNAL_ADMIN]
    },
    [enums_1.PrivilegeEnum.RESET_STUDENT_PASSWORDS]: {
        name: enums_1.PrivilegeEnum.RESET_STUDENT_PASSWORDS,
        description: 'Reset student passwords',
        display: 'Reset Student Passwords',
        category: PrivilegeCategory.STUDENTS,
        section: PrivilegeSection.STUDENTS,
        userTypes: [
            enums_1.UserTypeEnum.TEACHER,
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    },
    [enums_1.PrivilegeEnum.DEACTIVATE_RECORDS]: {
        name: enums_1.PrivilegeEnum.DEACTIVATE_RECORDS,
        description: 'Deactivate records',
        display: 'Deactivate Records',
        category: PrivilegeCategory.CONFIGURATION,
        section: PrivilegeSection.BEHAVIOR_CODE,
        userTypes: [
            enums_1.UserTypeEnum.SCHOOL,
            enums_1.UserTypeEnum.GROUP_ADMIN,
            enums_1.UserTypeEnum.INTERNAL_ADMIN
        ]
    }
};
