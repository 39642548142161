// Type definitions for w3c-web-usb
// Repository: https://www.npmjs.com/package/@types/w3c-web-usb
// Specification: https://wicg.github.io/webusb/
import { Injectable } from '@angular/core';

import { AppToastManagerService } from '../services/toast-manager.service';
import { logger } from '../../shared/logger';
import { USBDevice, USBDeviceOption } from './printer.interface';
import { MatDialog } from '@angular/material/dialog';
import { AppPrinterSelectorModal } from './printer-selector-modal.component';
import {
  PrinterConfigurationResponseDto,
  PrinterConfigurationSaveDto
} from '@whetstoneeducation/hero-common';
import { PrinterConfigurationService } from './printer-configuration.service';
import { DymoSdkDevicePrint } from './sdk-device-print.util';

@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  private printerConfiguration?: PrinterConfigurationResponseDto = null;
  private selectedUsbDeviceKey = 'selectedUsbDevice';
  public usbDeviceOptions: USBDeviceOption[] = [];
  public updater: () => void = () => {};
  public lastDeviceChanged: boolean = true;
  private interval?: NodeJS.Timeout = null;
  private intervalDuration: number = 1000;
  public usbDevices: USBDevice[] = [];
  public usbAllowed: boolean = false;
  private device?: USBDevice = null;
  private failAttempts: number = 3;
  private queue: (string | Uint8Array)[] = [];

  constructor(
    private toastService: AppToastManagerService,
    private printerConfigService: PrinterConfigurationService,
    private dialog: MatDialog
  ) {
    this.usbAllowed = 'usb' in navigator;
    if (this.usbAllowed) {
      this.loadDevices();
      dymo.label.framework.init();
    }
  }

  public get usbAvailable(): boolean {
    return this.device && this.usbAllowed && this.usbSupported;
  }

  public get usbNotConnected(): boolean {
    return this.device === null && this.usbAllowed;
  }

  public get usbSupported(): boolean {
    return !!this.printerConfiguration?.languageType;
  }

  public get configuration(): PrinterConfigurationResponseDto {
    return this.printerConfiguration;
  }

  public updateLastDeviceChanged(newValue?: boolean): void {
    this.lastDeviceChanged = newValue ?? !this.lastDeviceChanged;
  }

  private saveSelectedUsbDevice(): void {
    if (this.device) {
      const deviceInfo = {
        vendorId: this.device.vendorId,
        productId: this.device.productId
      };

      sessionStorage.setItem(
        this.selectedUsbDeviceKey,
        JSON.stringify(deviceInfo)
      );
    }
  }

  public async loadSelectedUsbDevice(
    skipConnectedValidation: boolean = false
  ): Promise<void> {
    const storedDeviceData = sessionStorage.getItem(this.selectedUsbDeviceKey);
    if (storedDeviceData && (this.usbNotConnected || skipConnectedValidation)) {
      const { vendorId, productId } = JSON.parse(storedDeviceData);

      const deviceFound = this.getDevice(vendorId, productId);
      this.device = deviceFound ?? null;
      await this.setPrinterConfigData();
    }
  }

  private cleanInterval(): void {
    clearTimeout(this.interval);
    this.interval = null;
  }

  private cleanPrinter(): void {
    this.failAttempts = 3;
    this.device = null;
    this.queue = [];
    this.cleanInterval();
    this.updater();
  }

  private async performWebUsbDevicePrint(
    item: string | Uint8Array | null
  ): Promise<void> {
    console.log(this.device);
    await this.device.open();
    await this.device.selectConfiguration(
      this.device.configurations[0].configurationValue
    );
    await this.device.claimInterface(
      this.device.configuration.interfaces[0].interfaceNumber
    );
    const endpoint =
      this.device.configuration.interfaces[0].alternate.endpoints.find(
        (obj) => obj.direction === 'out'
      ).endpointNumber;
    const data =
      typeof item === 'string'
        ? new Uint8Array(new TextEncoder().encode(item))
        : item;
    console.log(item);
    const res = await this.device.transferOut(endpoint, data);
    console.log(res);
    await this.device.close();
  }

  private async print(item: string | Uint8Array | null): Promise<void> {
    if (!this.device || item === null) {
      this.toastService.info('No USB device found');
      return;
    }

    try {
      switch (this.configuration.languageType) {
        case 'dymo':
          new DymoSdkDevicePrint(
            this.toastService,
            this.configuration
          ).performSdkDevicePrint(item as string);
          break;
        default:
          await this.performWebUsbDevicePrint(item);
          break;
      }

      this.failAttempts = 3;
    } catch (error) {
      if (this.failAttempts) {
        if (this.failAttempts === 3) {
          this.toastService.error(
            'Error while printing on the USB device, trying again...'
          );
        }

        logger.error(error);
        console.log(error);

        await this.loadSelectedUsbDevice(true);
        this.addToPrintQueue(item);
        this.failAttempts--;
      } else {
        this.toastService.error(
          'Too many fails in a row while printing, please select an USB Device'
        );
        this.cleanPrinter();
      }
    }
  }

  private processPrintQueue(): void {
    if (this.usbAvailable) {
      this.queue.length > 0
        ? this.print(this.queue.shift())
        : this.cleanInterval();
    } else {
      this.cleanInterval();
    }
  }

  private async getDevices(): Promise<USBDevice[]> {
    return await navigator.usb.getDevices();
  }

  private loadDevicesOptions(): void {
    this.usbDeviceOptions = this.usbDevices.map((device) => {
      return {
        vendorId: device.vendorId,
        productId: device.productId,
        productName: device.productName
      };
    });
  }

  public async initializeDevice(): Promise<void> {
    if (this.usbAllowed) {
      await this.loadSelectedUsbDevice();
      if (this.usbNotConnected) {
        this.connectUsbDeviceDialog();
      }
      this.updater();
    } else {
      this.toastService.info('USB Devices not supported on this browser');
    }
  }

  private async setPrinterConfigData(): Promise<void> {
    if (this.device) {
      const printerData = new PrinterConfigurationSaveDto({
        productId: this.device.productId,
        productName: this.device.productName,
        vendorId: this.device.vendorId,
        manufacturerName: this.device.manufacturerName,
        serialNumber: this.device.serialNumber
      });
      this.printerConfiguration =
        await this.printerConfigService.getPrinterConfiguration(printerData);
      if (!this.usbSupported) {
        this.toastService.info(
          'Selected usb device is not supported yet, please contact customer service.'
        );
      }
    }
    this.updater();
  }

  public async loadDevices(): Promise<void> {
    this.usbDevices = await this.getDevices();
    this.loadDevicesOptions();
  }

  public getDevice(vendorId: number, productId: number): USBDevice {
    return this.usbDevices.find((device) => {
      return device.vendorId === vendorId && device.productId === productId;
    });
  }

  public getDeviceByName(productName: string): USBDevice {
    return this.usbDevices.find((device) => device.productName === productName);
  }

  public async connectUsbDeviceDialog(): Promise<void> {
    if (this.usbAllowed) {
      this.dialog.open(AppPrinterSelectorModal, {
        data: {
          onClose: async () => {
            this.dialog.closeAll();
          },
          allowConnectUsbDevice: async () => {
            await this.connectUsbDevice();
            this.updateLastDeviceChanged(true);
            await this.setPrinterConfigData();
          },
          selectUsbDevice: async (deviceName: string) => {
            this.device = this.getDeviceByName(deviceName);
            this.saveSelectedUsbDevice();
            this.updateLastDeviceChanged(true);
            await this.setPrinterConfigData();
          }
        }
      });
    }
  }

  /**
   * Connects a new USB device
   */
  public async connectUsbDevice(): Promise<void> {
    try {
      this.device = await navigator.usb.requestDevice({ filters: [] });
      this.saveSelectedUsbDevice();
    } catch (error) {
      this.toastService.error('Error initializing the USB device');
      logger.error(error);
    }
  }

  /**
   * Initializes the print queue and adds a new item to it
   *
   * @param item Item to be printed
   */
  public addToPrintQueue(item: string | Uint8Array | null): void {
    this.queue.push(item);

    if (!this.interval) {
      this.interval = setInterval(
        () => this.processPrintQueue(),
        this.intervalDuration
      );
    }
  }
}
