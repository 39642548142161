import { Component, Input } from '@angular/core';
import { AccruedReportRowResponseDto } from '@whetstoneeducation/hero-common';
import { ITableFilters } from '../../../../shared/tables/table-filters/table-filters.interface';

export enum AccruedReportTableColumns {
  STUDENT_ID = 'Student ID',
  STUDENT_NAME = 'Student Name',
  GRADE = 'Grade Level',
  COUNT = 'Count',
  BEHAVIOR_CODE_DESCRIPTION = 'Behavior Code Description'
}

@Component({
  selector: 'app-accrued-report-table',
  templateUrl: './accrued-report-table.template.html',
  styleUrls: []
})
export class AppAccruedReportTable {
  @Input() showPagination: boolean;
  @Input() dataArr: AccruedReportRowResponseDto[];
  @Input() tableFilters: ITableFilters;

  public columns: typeof AccruedReportTableColumns = AccruedReportTableColumns;

  public constructor() {
  }

  public getColumnValue(column: string, row: any): any {
    switch (column) {
      case AccruedReportTableColumns.STUDENT_ID:
        return row.studentId;
      case AccruedReportTableColumns.STUDENT_NAME:
        return row.studentName;
      case AccruedReportTableColumns.GRADE:
        return row.grade;
      case AccruedReportTableColumns.COUNT:
        return row.count;
      case AccruedReportTableColumns.BEHAVIOR_CODE_DESCRIPTION:
        return row.behaviorCodeDescription;
      default:
        return null;
    }
  }
}
