import Handlebars from 'handlebars';

export interface StudentAccountPrintOptions {
  onePerPage: boolean; // If true, print one entry per page; if false, print multiple entries per page
}

export function browserPrintSubjectBody(subject: string, body: string): void {
  const printWindow = window.open('', '', 'width=800,height=600');
  if (printWindow) {
    printWindow.document.open();
    printWindow.document.write(`
        <html lang="en-US">
          <head>
            <style>
              @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
              body {
                font-family: 'Open Sans', sans-serif;
              }
            </style>
            <title>${subject}</title>
          </head>
          <body onload="window.print();window.close()">
            ${body}
          </body>
        </html>
      `);
    printWindow.document.close();
  }
}

const studentLoginInstructions = `
    <div class="instructions">
      <p>To access your account, follow these steps:</p>
      <ol>
        <li>Visit <a href="https://hero.schoolmint.com">hero.schoolmint.com</a>.</li>
        <li>Enter your email: <strong>{{email}}</strong> and the password provided above.</li>
        <li>Click on the login button.</li>
        <li>Follow the instructions to complete the login process.</li>
      </ol>
    </div>`;

const studentPasswordResetStyling = `
  <style>
    @import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&display=swap');
    body {
      font-family: 'Open Sans', sans-serif;
    }
    @media print {
      .page-break {
        page-break-before: always;
      }
      .entry {
        margin-bottom: 20px;
        border-bottom: {{options.borderBottom}};
        padding-bottom: {{options.paddingBottom}};
      }
    }
    .instructions {
      font-size: 14px;
      margin-top: 20px;
    }
  </style>`;

function printHandlebarsTemplate(rawTemplate: string, data: any): void {
  const printWindow = window.open('', '', 'width=800,height=600');
  if (printWindow) {
    printWindow.document.open();
    // Compile the Handlebars template
    const compiledTemplate = Handlebars.compile(rawTemplate);

    // Generate the HTML content using the compiled template
    const documentContent = compiledTemplate(data);

    // Write the generated HTML content to the new window
    printWindow.document.write(documentContent);
    printWindow.document.close();

    // Trigger the print dialog once the content is loaded
    printWindow.onload = () => {
      printWindow.print();
      printWindow.close();
    };
  } else {
    //todo make this an enum
    throw new Error('popups likely blocked');
  }
}

export function printSingleStudentPassword(
  email: string,
  password: string
): void {
  const template = `
    <html lang="en-US">
      <head>
        <title>Account Information</title>
        ${studentPasswordResetStyling}
      </head>
      <body>
        <div class="entry">
          <h1>${email}</h1>
          <p>Your new password is: <strong>${password}</strong></p>
          ${studentLoginInstructions}
        </div>
      </body>
    </html>`;

  const data = {
    email,
    password,
    options: {
      borderBottom: 'none',
      paddingBottom: '20px'
    }
  };
  printHandlebarsTemplate(template, data);
}

export function printBulkStudentPasswords(
  emailPasswordMap: Map<string, string>,
  options: StudentAccountPrintOptions
): void {
  // Define the Handlebars template
  const template = `
        <html lang="en-US">
          <head>
            <title>Account Information</title>
            ${studentPasswordResetStyling}
          </head>
          <body>
            {{#each entries}}
              <div class="entry">
                <h1>{{email}}</h1>
                <p>Your new password is: <strong>{{password}}</strong></p>
                ${studentLoginInstructions}
              </div>
              {{#if ../options.pageBreak}}
                <div class="page-break"></div>
              {{/if}}
            {{/each}}
          </body>
        </html>`;

  const borderBottom = options.onePerPage ? 'none' : '1px dashed #000';
  const data = {
    // Convert the Map to an array of objects for Handlebars
    entries: Array.from(emailPasswordMap, ([email, password]) => ({
      email,
      password
    })),
    options: {
      ...options,
      borderBottom,
      paddingBottom: '40px',
      pageBreak: borderBottom === 'none'
    }
  };

  // Print the email template
  printHandlebarsTemplate(template, data);
}
