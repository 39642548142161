import { NgModule } from '@angular/core';
import { AppMaterialModule } from '../../app-material.module';
import { AppMultipleSelectModule } from '../../shared/dropdowns/multi-select/multiple-select.module';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { AppPaginationModule } from '../../shared/tables/pagination/pagination.module';
import { RouterLink } from '@angular/router';
import { AppDailyActivityReportTable } from './daily-activity-report/daily-activity-report-table/daily-activity-report-table.component';
import { AppDailyActivityReportFiltersComponent } from './daily-activity-report/daily-activity-report-filters/daily-activity-report-filter.component';
import { AppErrorDisplayComponent } from '../../shared/validation/error-display.component';
import { DirectiveModule } from '../../shared/directives/directive.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppAccruedReportComponent } from './accrued-report/accrued-report.component';
import { AppAccruedReportTable } from './accrued-report/accrued-report-table/accrued-report-table.component';
import { AppAccruedReportFilters } from './accrued-report/accrued-report-filters/accrued-report-filters.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { AppAccruedReportSummaryCard } from './accrued-report/accrued-report-summary-card/accrued-report-summary-card.compent';
import { AppAccruedReportActions } from './accrued-report/accrued-report-actions/accrued-report-actions.component';
import { AppTablesModule } from '../../shared/tables/tables.module';
import { AppSchoolActivityReportFilters } from './school-activity-report/school-activity-report-filters/school-activity-report-filters.component';
import { AppSchoolActivityReportComponent } from './school-activity-report/school-activity-report.component';
import { AppInputsModule } from '../../shared/inputs/inputs.module';
import { AppSchoolActivityReportActions } from './school-activity-report/school-activity-report-actions/school-activity-report-actions.component';
import { AppSchoolActivityReportTable } from './school-activity-report/school-activity-report-table/school-activity-report-table.component';
import { AppSchoolActivityReportSummaryCard } from './school-activity-report/school-activity-report-summary-card/school-activity-report-summary-card.component';
import { AppSchoolwideReportComponent } from './schoolwide-report/schoolwide-report.component';
import { AppSchoolwideReportTable } from './schoolwide-report/schoolwide-report-table/schoolwide-report-table.component';
import { AppSchoolwideReportFilters } from './schoolwide-report/schoolwide-report-filters/schoolwide-report-filters.component';
import { AppSchoolwideReportActions } from './schoolwide-report/schoolwide-report-actions/schoolwide-report-actions.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { AppCommunicationsReportFilters } from './communications-report/communications-report-filters/communications-report-filters.component';
import { AppViewCommunicationDialogComponent } from './communications-report/view-communication-dialog/view-communication-dialog.component';
import { AppCommunicationsReportTable } from './communications-report/communications-report-table/communications-report-table.component';

@NgModule({
  declarations: [
    AppDailyActivityReportTable,
    AppDailyActivityReportFiltersComponent,
    AppAccruedReportComponent,
    AppAccruedReportTable,
    AppAccruedReportFilters,
    AppAccruedReportSummaryCard,
    AppAccruedReportActions,
    AppSchoolActivityReportComponent,
    AppSchoolActivityReportTable,
    AppSchoolActivityReportFilters,
    AppSchoolActivityReportActions,
    AppSchoolActivityReportSummaryCard,
    AppSchoolwideReportComponent,
    AppSchoolwideReportTable,
    AppSchoolwideReportFilters,
    AppSchoolwideReportActions,
    AppCommunicationsReportFilters,
    AppCommunicationsReportTable,
    AppViewCommunicationDialogComponent
  ],
  exports: [],
  imports: [
    AppMaterialModule,
    AppMultipleSelectModule,
    FontAwesomeModule,
    RouterLink,
    AppPaginationModule,
    AppErrorDisplayComponent,
    DirectiveModule,
    FormsModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    AppTablesModule,
    AppInputsModule,
    MatTooltipModule
  ],
  providers: []
})
export class AppReportsModule {}
