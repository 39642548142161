import { Component, EventEmitter, Input, Output } from '@angular/core';
import { BaseComponent } from '../../../../shared/base-classes/base.component';
import { AccruedReportFiltersDto } from '@whetstoneeducation/hero-common';
import { faDownToLine } from '@fortawesome/pro-solid-svg-icons';

@Component({
  selector: 'app-accrued-report-actions',
  templateUrl: './accrued-report-actions.template.html',
  styleUrls: ['./accrued-report-actions.scss']
})
export class AppAccruedReportActions extends BaseComponent {
  public downloadIcon = faDownToLine;

  @Input()
  public filters: AccruedReportFiltersDto;
  @Output()
  private filtersUpdated: EventEmitter<AccruedReportFiltersDto> = new EventEmitter<AccruedReportFiltersDto>();

  public constructor(
  ) {
    super();
  }

  public onFilterUpdated(field: string, data?: string | boolean) {
    this.filters[field] = data;
    this.filtersUpdated.emit(this.filters);
  }

  public searchFilterUpdated(data: string | undefined) {
    this.onFilterUpdated('text', data);
  }
}
